import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const AddFeatureSection = () => {
  const [service, setService] = useState([]);
  const [items, setItems] = useState([]);


  const [page, setPage] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState(""); // State to store pageSlug

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
      .then((res) => res.json())
      .then((info) => setPage(info));
  }, []);

  const handlePageChange = (e) => {
    const selectedPage = page.find((p) => p.pageName === e.target.value);
    setSelectedSlug(selectedPage ? selectedPage.pageSlug : ""); // Store selectedSlug
  };

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featurestext`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featureitems`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);

  const handleServiceSection = (event) => {
    event.preventDefault();
    const pageName = event.target.pageName.value;
    const FeaturesText = event.target.FeaturesText.value;
    const FeaturesHeading = event.target.FeaturesHeading.value;

    const serviceSection = {
      pageName,
      pageSlug: selectedSlug, // Corrected - adding pageSlug here
      FeaturesText,
      FeaturesHeading,
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-feature-text`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(serviceSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Feature is Updated');
      });
  };

  const handleAddItem = (event) => {
    event.preventDefault();

    const pageName = event.target.pageName.value;
    const FeaturesIcon = event.target.FeaturesIcon.value;
    const FeaturesText = event.target.FeaturesText.value;
    const FeaturesDesc = event.target.FeaturesDesc.value;

    const itemSection = {
      pageName,
      pageSlug: selectedSlug, // Corrected - adding pageSlug here
      FeaturesIcon,
      FeaturesText,
      FeaturesDesc,
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-feature-Item`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(itemSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Item is Added');
      });
  };

  return (
    <>

      <HeaderBottom></HeaderBottom>
      <section id="services" className="services-area  d-flex justify-content-center align-items-center">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">

              {/* Display Pages in a Table */}
              <h3 className="mt-5">Feature Section Text</h3>
              <table className="table table-bordered">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Sl No</th>
                    <th>Page Name</th>
                    <th>Heading About</th>
                    <th>Edit/Action</th>
                  </tr>
                </thead>
                <tbody>
                  {service.map((page, index) => (
                    <tr key={page._id}>
                      <td>{index + 1}</td>
                      <td>{page.pageName}</td>
                      <td>{page.FeaturesText}</td>
                      <td>
                        <Link to={`/featurestext-edit/${page._id}`} className="btn btn-sm btn-warning">
                        <i className="fas fa-edit"></i>  Edit Now
                        </Link>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>

              <form className="contact-form" onSubmit={handleServiceSection}>

                <div className="text-black">
                  <select
                    name="pageName"
                    className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                    required
                    style={{ color: 'black' }}
                    onChange={handlePageChange}
                  >
                    <option value="" disabled selected>
                      Select Page
                    </option>
                    {page.map((p) => (
                      <option key={p._id} value={p.pageName}>
                        {p.pageName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Display the selected pageSlug in an input */}
                <div className="col-lg-12 col-md-12 mb-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSlug} // Set value from state
                      readOnly
                      placeholder="Page Slug"
                    />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="FeaturesText"
                        placeholder="Features Text"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="FeaturesHeading"
                        placeholder="Features Heading"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className="btn btn-primary btn-lg"
                      data-animation="fadeInRight"
                      data-delay=".8s"
                    >
                      Update Feature
                    </button>
                  </div>
                </div>
              </form>


            


              {/* Display Pages in a Table */}
              <h3 className="mt-5">Feature Section Text</h3>
              <table className="table table-bordered">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Sl No</th>
                    <th>Page Name</th>
                    <th>Heading About</th>
                    <th>Edit/Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((s, index) => (
                    <tr key={s._id}>
                      <td>{index + 1}</td>
                      <td>{s.pageName}</td>
                      <td>{s.FeaturesText}</td>
                      <td>
                        <Link to={`/edit-feature-items/${s._id}`} className="btn btn-sm btn-warning">
                        <i className="fas fa-edit"></i>  Edit Now
                        </Link>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>

              <form className="contact-form" onSubmit={handleAddItem}>
                <div className="row justify-content-center">

                <h2 className="mt-5 mb-5">Add Feature Items</h2>

                <div className="text-black">
                  <select
                    name="pageName"
                    className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                    required
                    style={{ color: 'black' }}
                    onChange={handlePageChange}
                  >
                    <option value="" disabled selected>
                      Select Page
                    </option>
                    {page.map((p) => (
                      <option key={p._id} value={p.pageName}>
                        {p.pageName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Display the selected pageSlug in an input */}
                <div className="col-lg-12 col-md-12 mb-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSlug} // Set value from state
                      readOnly
                      placeholder="Page Slug"
                    />
                  </div>
                </div>

                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="FeaturesIcon"
                        placeholder="Service Image"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        name="FeaturesText"
                        placeholder="Feature Title"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        name="FeaturesDesc"
                        placeholder="Feature Details"
                        required
                      />
                    </div>
                  </div>
                  <div className="slider-btn">
                    <button
                      className="btn btn-primary btn-lg"
                      data-animation="fadeInRight"
                      data-delay=".8s"
                    >
                      Add Feature
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

         
        </div>
      </section>
      <MarginTop></MarginTop>
    </>
  );
};

export default AddFeatureSection;
