import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateAboutSectionFive = () => {

    const [about, setAbout] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/about-five/${id}`)
            .then((res) => res.json())
            .then((info) => setAbout(info));
    }, [id]);

    const handleAboutSection = (event) => {
        event.preventDefault();

        const aboutImgOneFive = event.target.aboutImgOneFive.value;
        const aboutHeadingFive = event.target.aboutHeadingFive.value;
        const aboutDetailsFive = event.target.aboutDetailsFive.value;
        const aboutTextFive = event.target.aboutTextFive.value;
        const pointOneFive = event.target.pointOneFive.value;
        const pointOneTextFive = event.target.pointOneTextFive.value;
        const pointTwoFive = event.target.pointTwoFive.value;
        const pointTwoTextFive = event.target.pointTwoTextFive.value;

        const aboutSection = {
            aboutImgOneFive,
            aboutHeadingFive,
            aboutDetailsFive,
            aboutTextFive,
            pointOneFive,
            pointOneTextFive,
            pointTwoFive,
            pointTwoTextFive,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-about-five/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(aboutSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('About is Updated');
            });
    };

    return (
        <>

        <HeaderBottom></HeaderBottom>
            
            <section id="services" className="services-area vh-100 d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 text-center">
                            <form className="contact-form" onSubmit={handleAboutSection}>
                                <h2 className='mb-5'>Update About</h2>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutImgOneFive" defaultValue={about.aboutImgOneFive} placeholder="Image One" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutHeadingFive" defaultValue={about.aboutHeadingFive} placeholder="About Heading" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutDetailsFive" defaultValue={about.aboutDetailsFive} placeholder="About Details" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutTextFive" defaultValue={about.aboutTextFive} placeholder="About Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOneFive" defaultValue={about.pointOneFive} placeholder="Point One" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOneTextFive" defaultValue={about.pointOneTextFive} placeholder="Point One Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwoFive" defaultValue={about.pointTwoFive} placeholder="Point Two" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwoTextFive" defaultValue={about.pointTwoTextFive} placeholder="Point Two Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">Update About</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UpdateAboutSectionFive;
