import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import './Banner.css';

const CounterSection = () => {
  
    const [counter, setCounter] = useState([]);
      const [page, setPage] = useState([]);
        const { pageSlug } = useParams(); // Extract the blog post titleSlug from the URL
    
      useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/counters`)
          .then((res) => res.json())
          .then((info) => setCounter(info));
      }, []);
    
       useEffect(() => {
          fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page/${pageSlug}`)
            .then((res) => res.json())
            .then((info) => setPage(info))
            .catch((error) => console.error("Error fetching banners:", error));
        }, []);



    return (



        <>

            {
                counter.map(b => b.pageSlug === page.pageSlug &&


                    <div className="counter1-section-area sp1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="counter-auhtor-area"
                                        data-aos="fade-up"
                                        data-aos-duration={800}
                                    >
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="counter-box1 text-center">
                                                    <h3>
                                                        <span className="counter">{b.happyClientsNumber}</span>
                                                    </h3>
                                                    <p>{b.happyClientsText}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div
                                                    className="counter-box1 text-center"
                                                    data-aos="fade-up"
                                                    data-aos-duration={1000}
                                                >
                                                    <h3>
                                                        <span className="counter">{b.projectCompleteNumber}</span>+
                                                    </h3>
                                                    <p>{b.projectCompleteText}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div
                                                    className="counter-box1 text-center"
                                                    data-aos="fade-up"
                                                    data-aos-duration={1200}
                                                >
                                                    <h3>
                                                        <span className="counter">{b.yearofExperienceNumber}</span>
                                                    </h3>
                                                    <p>{b.yearofExperienceText}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div
                                                    className="counter-box1 text-center counter-after"
                                                    data-aos="fade-up"
                                                    data-aos-duration={1400}
                                                >
                                                    <h3>
                                                        <span className="counter">{b.teamNumber}</span>+
                                                    </h3>
                                                    <p>{b.teamText}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}





        </>
    );
};

export default CounterSection;
