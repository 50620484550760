import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSectionThree = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/about-three`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);





  return (



    <>

      {
        about.map(a => <section className="about-style-three pb_150" id="about-sec">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image_block_five">
                  <div className="image-box mr_80">
                    <figure className="image"><img src={a.aboutImgOneThree} alt /></figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_four">
                  <div className="content-box ml_40">
                    <div data-animation-box className="sec-title mb_25">
                      <span data-animation-text className="sub-title " >{a.aboutTextThree}</span>
                      <h2>{a.aboutHeadingThree}</h2>
                    </div>
                    <div className="text-box mb_40">
                      <p>{a.aboutDetailsThree}</p>
                    </div>
                    <div className="inner-box">
                      <div className="single-item mb_40">
                        <div className="icon-box"><i className="icon-13" /></div>
                        <h3>{a.pointOneThree}</h3>
                        <p>{a.pointOneTextThree}</p>
                      </div>
                      <div className="single-item">
                        <div className="icon-box"><i className="icon-10" /></div>
                        <h3>{a.pointTwoThree}</h3>
                        <p>{a.pointTwoTextThree}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>)}
    </>
  );
};

export default AboutSectionThree;
