import React, { useEffect, useState } from 'react';

const Preloader = () => {
    const [loading, setLoading] = useState(true);

    // Simulate a page load or data fetching
    useEffect(() => {
        setTimeout(() => {
            setLoading(false); // Hide preloader after 1 second
        }, 1000); // Adjust the delay to 1 second
    }, []);

    if (loading) {
        return (
            <div id="preloader" style={styles.preloader}>
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return null;
};

const styles = {
    preloader: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        transition: 'opacity 0.3s ease', // Smooth fade-out effect
    },
    spinner: {
        width: '5rem',
        height: '5rem',
        animation: 'spin 1s linear infinite',
    },
};

export default Preloader;
