import React, { useEffect, useState } from "react";

const TestimonialSectionThree = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonialtext-three`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonials-three`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (



    <>
      {/* testimonial-section */}
      <section className="testimonial-section centred pb_140">
        <div className="shape">
          <div className="shape-1 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-45.png)' }} />
          <div className="shape-2" style={{ backgroundImage: 'url(assets/images/shape/shape-46.png)' }} />
        </div>
        <div className="auto-container">
          {
            testimonialtext.map(t => <div className="sec-title mb_55">
              <span className="sub-title" >{t.testimonialTextThree}</span>
              <h2>{t.testimonialHeadingThree}</h2>
            </div>)
          }

          <div className="container">
            <div className="row three-item-carousel dots-style-one">
              {
                testimonial.map(a => <div className="col-md-4">
                  <div className="testimonial-block-one">
                    <div className="inner-box">
                      <figure className="thumb-box"><img src={a.imageThree} alt /></figure>
                      <h4>{a.personNameThree}</h4>
                      <span className="designation">{a.personTitleThree}</span>
                      <ul className="rating">
                        <li><i className="icon-19" /></li>
                        <li><i className="icon-19" /></li>
                        <li><i className="icon-19" /></li>
                        <li><i className="icon-19" /></li>
                        <li><i className="icon-19" /></li>
                      </ul>
                      <p>{a.descThree}</p>
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </section>


    </>



  );
};

export default TestimonialSectionThree;
