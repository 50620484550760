import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateBannerSectionThree = () => {
    const [banner, setBanner] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner-three/${id}`)
            .then((res) => res.json())
            .then((info) => setBanner(info));
    }, [banner]);

    const handleBannerSection = (event) => {
        event.preventDefault();
        const bannerHeadingThree = event.target.bannerHeadingThree.value;
        const bannerDetailsThree = event.target.bannerDetailsThree.value;
        const bannerTextThree = event.target.bannerTextThree.value;
        const imageOneThree = event.target.imageOneThree.value;
        const buttonTextThree = event.target.buttonTextThree.value;
        const buttonURLThree = event.target.buttonURLThree.value;
        const buttonTextTwoThree = event.target.buttonTextTwoThree.value;
        const bannerTextTwoThree = event.target.bannerTextTwoThree.value;

        const bannerSection = {
            bannerHeadingThree,
            bannerDetailsThree,
            bannerTextThree,
            imageOneThree,
            buttonTextThree,
            buttonURLThree,
            buttonTextTwoThree,
            bannerTextTwoThree,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-banner-three/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bannerSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Banner is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <div>
                <section id="services" className="services-area vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h2 className='mb-5 mt-5'>Update Banner</h2>
                                <form className="contact-form" onSubmit={handleBannerSection}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingThree" defaultValue={banner.bannerHeadingThree} placeholder="Banner Heading One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextTwoThree" defaultValue={banner.bannerTextTwoThree} placeholder="Banner Heading Two" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextThree" defaultValue={banner.bannerTextThree} placeholder="Banner Heading Three" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerDetailsThree" defaultValue={banner.bannerDetailsThree} placeholder="Banner Details " required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="imageOneThree" defaultValue={banner.imageOneThree} placeholder="Image One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextThree" defaultValue={banner.buttonTextThree} placeholder="Button Text" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 ">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextTwoThree" defaultValue={banner.buttonTextTwoThree} placeholder="Reviews Text" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonURLThree" defaultValue={banner.buttonURLThree} placeholder="Button URL" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-center">
                                            <button className="btn btn-primary btn-lg mt-4">Update Banner</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <HeaderBottom />
            </div>
        </>
    );
};

export default UpdateBannerSectionThree;
