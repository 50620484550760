import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const EditCategory = () => {
    const [category, setCategory] = useState({
        categoryName: '',
        categoryImage: '',
    });

    const navigate = useNavigate(); // Initialize the navigate function
    const { categorySlug } = useParams(); // Extract categorySlug from the URL

    // Fetch the category details based on the categorySlug
    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/categorys/${categorySlug}`)
            .then((res) => res.json())
            .then((data) => setCategory(data))
            .catch((error) => console.error('Error fetching category:', error));
    }, [categorySlug]);

    // Handle category update
    const handleCategoryUpdate = (event) => {
        event.preventDefault();

        const updatedCategory = {
            categoryName: event.target.categoryName.value,
            categoryImage: event.target.categoryImage.value,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-category/${categorySlug}`;
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedCategory),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Category updated successfully!');
                navigate('/admin/category'); // Redirect to the categories list page
            })
            .catch((error) => console.error('Error updating category:', error));
    };

    return (
        <>
            <HeaderBottom />
            <MarginTop></MarginTop>
            <div>
                <section id="services" className="services-area flex items-center py-8 vh-100">
                    <div className="container mx-auto">
                        <div className="flex justify-center">
                            <div className="col-lg-12 text-center p-8">
                                <h2 className="text-3xl font-bold text-gray-800 mb-8">Edit Category</h2>

                                <form className="space-y-6" onSubmit={handleCategoryUpdate}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none text-black mb-3"
                                            name="categoryName"
                                            placeholder="Category Name"
                                            defaultValue={category.categoryName}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none text-black mb-3"
                                            name="categoryImage"
                                            placeholder="Category Image URL"
                                            defaultValue={category.categoryImage}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <button
                                            className="btn btn-primary btn-lg"
                                            type="submit"
                                        >
                                            Update Category
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default EditCategory;
