import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderBottom from "../../components/HomePage/HeaderBottom";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  useEffect(() => {
    fetch("https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/posts")
      .then((res) => res.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>

      {/*===== WELCOME STARTS =======*/}
      <div className="about-welcome-section-area about2" style={{ backgroundImage: 'url(assets/images/background/header2-bg.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 m-auto">
              <div className="about-welcome-header text-center heading3">
                <h1>All Blog Post</h1>
                <div className="space16" />
                <a href="/">Home <i className="fa-solid fa-angle-right" /> <span>Blog</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===== WELCOME ENDS =======*/}

      <main className="main-wrapper min-vh-100">
        <div className="blog1-section-area sp3">
          <div className="container">

            <div className="space60" />
            <div className="row">
              {currentPosts.map((post, index) => (
                <div
                  key={post._id}
                  className="col-lg-4 col-md-6"
                  data-aos="zoom-out"
                  data-aos-duration={800 + index * 200}
                >
                  <div className="blog1-boxarea">
                    <div className="blog-img">
                      <Link to={`/blog/${post.titleSlug}`}>
                        <img
                          src={post.featuredImage}
                          alt="Blog Thumbnail"
                          className="img-fluid"
                          style={{ height: "250px", objectFit: "", width: "100%" }}
                        />
                      </Link>
                    </div>
                    <div className="blog-all-content-area">
                      <div className="blog1-content">
                        <img src="assets/images/icons/calender-img1.svg" alt="Calendar" />
                        <span>{post.postDate}</span>
                      </div>
                      <div className="blog-main-content">
                        <h2><Link to={`/blog/${post.titleSlug}`}>
                          {post.postTitle}
                        </Link></h2>

                        <Link to={`/blog/${post.titleSlug}`} className="learnmore">
                          Read More <i className="fa-solid fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            {posts.length > postsPerPage && (
              <div className="col-lg-12 text-center mt-4">
                <button
                  className="header-btn"
                  onClick={() => setCurrentPage((prev) => (prev < Math.ceil(posts.length / postsPerPage) ? prev + 1 : prev))}
                >
                  View More Blogs <i className="fa-solid fa-arrow-right" />
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Blog;
