import React, { useEffect, useState } from 'react';

const FAQSectionThree = () => {
  const [countersec, setCounter] = useState([]);
  const [items, setItems] = useState([]);
  const [faq, setFAQsection] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  // Fetch FAQ section data
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/faq-three`)
      .then((res) => res.json())
      .then((info) => setFAQsection(info));
  }, []);

  // Fetch FAQ items data
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/faq-items-three`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);

  // Fetch counter section data
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/counters`)
      .then((res) => res.json())
      .then((info) => setCounter(info));
  }, []);

  // Accordion toggle handler
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      {
        faq.map(a => <section className="faq-style-three pb_140 pt_100">
          <div
            className="pattern-layer"
            style={{ backgroundImage: 'url(assets/images/shape/shape-44.png)' }}
          />
          <div className="auto-container">
            <div className="sec-title mb_55 text-center">
              <span className="sub-title">{a.faqTextThree}</span>
              <h2>{a.faqHeadingThree}</h2>
            </div>

            <div className="row clearfix">
              {/* FAQ Items Column */}
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="inner-box mr_30">
                  <ul className="accordion-box">
                    {items.map((item, index) => (
                      <li className="accordion block" key={item._id}>
                        <div
                          className={`acc-btn ${activeIndex === index ? 'active' : ''}`}
                          onClick={() => toggleAccordion(index)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="icon-box" />
                          <h4>{item.accodingTitleThree}</h4>
                        </div>
                        {/* Conditionally render the accordion content */}
                        <div
                          className="acc-content"
                          style={{ display: activeIndex === index ? 'block' : 'none' }}
                        >
                          <div className="text">
                            <p>{item.accodingTextThree}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Image Column */}
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image-box ml_30">
                  <figure className="image">
                    <img src={a.faqImageThree} alt="FAQ illustration" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>)}


    </>
  );
};

export default FAQSectionThree;
