import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSectionOne = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/service-item-one`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/service-one`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (




    <>  <section className="working-section centred  pb_120" id="services-sec">
      <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-4.png)' }} />
      <div className="auto-container">
        {
          service.map(a=><div className="sec-title mb_60">
            <span className="sub-title " >{a.serviceHeadingOne}</span>
            <h2>{a.servicesubHeadingOne}</h2>
          </div> )
        }
        
        <div className="inner-container">
          <div className="row clearfix">

            {
              items.map(e => <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div className="working-block-one">
                  <div className="inner-box">
                    <div className="upper-box">
                      <div >
                      <img src={e.serviceIconOne} alt="Service Icon" style={{ width: '50px', height: '50px' }} />
                      <div className="lower-content">
                      <h3>{e.serviceTitleOne}</h3>
                      <p>{e.serviceDetailsOne}</p>
                    </div>

                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>)
            }



          </div>
        </div>
      </div>
    </section>
    </>


  );
};

export default ServicesSectionOne;
