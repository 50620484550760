import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const ServicesSection = () => {
  const [service, setService] = useState([]);
  const [items, setItems] = useState([]);


  const [page, setPage] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState(""); // State to store pageSlug

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
      .then((res) => res.json())
      .then((info) => setPage(info));
  }, []);

  const handlePageChange = (e) => {
    const selectedPage = page.find((p) => p.pageName === e.target.value);
    setSelectedSlug(selectedPage ? selectedPage.pageSlug : ""); // Store selectedSlug
  };

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);

  const handleServiceSection = (event) => {
    event.preventDefault();
    const pageName = event.target.pageName.value;
    const servicesubHeading = event.target.servicesubHeading.value;
    const serviceHeading = event.target.serviceHeading.value;

    const serviceSection = {
      pageName,
      pageSlug: selectedSlug, // Corrected - adding pageSlug here
      servicesubHeading,
      serviceHeading,
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-Service`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(serviceSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Service is Updated');
      });
  };

  const handleAddItem = (event) => {
    event.preventDefault();

    const pageName = event.target.pageName.value;
    const serviceIcon = event.target.serviceIcon.value;
    const serviceTitle = event.target.serviceTitle.value;
    const serviceDetails = event.target.serviceDetails.value;

    const itemSection = {
      pageName,
      pageSlug: selectedSlug, // Corrected - adding pageSlug here
      serviceIcon,
      serviceTitle,
      serviceDetails,
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-service-item`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(itemSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Item is Added');
      });
  };

  return (
    <>

      <HeaderBottom></HeaderBottom>
      <section id="services" className="services-area  d-flex justify-content-center align-items-center">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">

              {/* Display Pages in a Table */}
              <h3 className="mt-5">Service Section Text</h3>
              <table className="table table-bordered">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Sl No</th>
                    <th>Page Name</th>
                    <th>Heading About</th>
                    <th>Edit/Action</th>
                  </tr>
                </thead>
                <tbody>
                  {service.map((page, index) => (
                    <tr key={page._id}>
                      <td>{index + 1}</td>
                      <td>{page.pageName}</td>
                      <td>{page.servicesubHeading}</td>
                      <td>
                        <Link to={`/servicetext-edit/${page._id}`} className="btn btn-sm btn-warning">
                        <i className="fas fa-edit"></i>  Edit Now
                        </Link>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>

              <form className="contact-form" onSubmit={handleServiceSection}>

                <div className="text-black">
                  <select
                    name="pageName"
                    className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                    required
                    style={{ color: 'black' }}
                    onChange={handlePageChange}
                  >
                    <option value="" disabled selected>
                      Select Page
                    </option>
                    {page.map((p) => (
                      <option key={p._id} value={p.pageName}>
                        {p.pageName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Display the selected pageSlug in an input */}
                <div className="col-lg-12 col-md-12 mb-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSlug} // Set value from state
                      readOnly
                      placeholder="Page Slug"
                    />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="serviceHeading"
                        placeholder="Service Heading"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="servicesubHeading"
                        placeholder="Service Sub Heading"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className="btn btn-primary btn-lg"
                      data-animation="fadeInRight"
                      data-delay=".8s"
                    >
                      Update Services
                    </button>
                  </div>
                </div>
              </form>


            


              {/* Display Pages in a Table */}
              <h3 className="mt-5">Service Section Text</h3>
              <table className="table table-bordered">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Sl No</th>
                    <th>Page Name</th>
                    <th>Heading About</th>
                    <th>Edit/Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((s, index) => (
                    <tr key={s._id}>
                      <td>{index + 1}</td>
                      <td>{s.pageName}</td>
                      <td>{s.serviceTitle}</td>
                      <td>
                        <Link to={`/service-edit/${s._id}`} className="btn btn-sm btn-warning">
                        <i className="fas fa-edit"></i>  Edit Now
                        </Link>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>

              <form className="contact-form" onSubmit={handleAddItem}>
                <div className="row justify-content-center">

                <h2 className="mt-5 mb-5">Add Service Items</h2>

                <div className="text-black">
                  <select
                    name="pageName"
                    className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                    required
                    style={{ color: 'black' }}
                    onChange={handlePageChange}
                  >
                    <option value="" disabled selected>
                      Select Page
                    </option>
                    {page.map((p) => (
                      <option key={p._id} value={p.pageName}>
                        {p.pageName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Display the selected pageSlug in an input */}
                <div className="col-lg-12 col-md-12 mb-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSlug} // Set value from state
                      readOnly
                      placeholder="Page Slug"
                    />
                  </div>
                </div>

                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="serviceIcon"
                        placeholder="Service Image"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        name="serviceTitle"
                        placeholder="Service Title"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        name="serviceDetails"
                        placeholder="Service Details"
                        required
                      />
                    </div>
                  </div>
                  <div className="slider-btn">
                    <button
                      className="btn btn-primary btn-lg"
                      data-animation="fadeInRight"
                      data-delay=".8s"
                    >
                      Add Service
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

         
        </div>
      </section>
      <MarginTop></MarginTop>
    </>
  );
};

export default ServicesSection;
