import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const FAQtextEditFive = () => {

    const [faqTextFive, setService] = useState([]);
    const { id } = useParams();


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/faq-five/${id}`)
            .then((res) => res.json())
            .then((info) => setService(info));
    }, []);




    const handlefaqTextFive = (event) => {
        event.preventDefault();
        const faqTextFive = event.target.faqTextFive.value;
        const faqHeadingFive = event.target.faqHeadingFive.value;
        const faqImageFive = event.target.faqImageFive.value;



        const faqSection = {
            faqTextFive,
            faqHeadingFive,
            faqImageFive,


        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/faq-five/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(faqSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' FAQ Text is Updated');
            });
    };




    return (
        <>
            <HeaderBottom></HeaderBottom>
            {
                <section id="services" className="services-area  vh-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h3>Update FAQ Text</h3>

                                <form className="contact-form" onSubmit={handlefaqTextFive}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name ">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="faqTextFive"
                                                    defaultValue={faqTextFive.faqTextFive}
                                                    placeholder="FAQ Text"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name ">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="faqHeadingFive"
                                                    defaultValue={faqTextFive.faqHeadingFive}
                                                    placeholder="FAQ Heading"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name ">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="faqImageFive"
                                                    defaultValue={faqTextFive.faqImageFive}
                                                    placeholder="FAQ Heading"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="slider-btn text-center">
                                            <button
                                                className="btn btn-primary btn-lg"
                                                data-animation="fadeInRight"
                                                data-delay=".8s"
                                            >
                                                Update FAQ Text
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default FAQtextEditFive;