import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateBannerSectionTwo = () => {
    const [banner, setBanner] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner-two/${id}`)
            .then((res) => res.json())
            .then((info) => setBanner(info));
    }, [banner]);

    const handleBannerSectionTwo = (event) => {
        event.preventDefault();
        const bannerHeadingTwo = event.target.bannerHeadingTwo.value;
        const bannerDetailsTwo = event.target.bannerDetailsTwo.value;
       const imageOneTwo = event.target.imageOneTwo.value;
        const buttonTextTwo = event.target.buttonTextTwo.value;
        const buttonURLTwo = event.target.buttonURLTwo.value;
        const buttonTextTwoTwo = event.target.buttonTextTwoTwo.value;
      
        const bannerTextTwoTwo = event.target.bannerTextTwoTwo.value;
        const bannerTextTwo = event.target.bannerTextTwo.value;

        const bannerSection = {
            bannerHeadingTwo,
            bannerDetailsTwo,
            bannerTextTwo,
            imageOneTwo,
            buttonTextTwo,
            buttonURLTwo,
            buttonTextTwoTwo,
    
            bannerTextTwoTwo,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-banner-two/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bannerSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Banner is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <div>
                <section id="services" className="services-area vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h2 className='mb-5 mt-5'>Update Banner</h2>
                                <form className="contact-form" onSubmit={handleBannerSectionTwo}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingTwo" defaultValue={banner.bannerHeadingTwo} placeholder="Banner Heading One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextTwoTwo" defaultValue={banner.bannerTextTwoTwo} placeholder="Banner Heading Two" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextTwo" defaultValue={banner.bannerTextTwo} placeholder="Banner Heading Three" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerDetailsTwo" defaultValue={banner.bannerDetailsTwo} placeholder="Banner Details " required />
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="imageOneTwo" defaultValue={banner.imageOneTwo} placeholder="Image One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextTwo" defaultValue={banner.buttonTextTwo} placeholder="Button Text" required />
                                            </div>
                                        </div>
                                     
                                        <div className="col-lg-12 col-md-12 ">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextTwoTwo" defaultValue={banner.buttonTextTwoTwo} placeholder="Reviews Text" required />
                                            </div>
                                        </div>
                                 
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonURLTwo" defaultValue={banner.buttonURLTwo} placeholder="Button URL" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-center">
                                            <button className="btn btn-primary btn-lg mt-4">Update Banner</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
               
            </div>
        </>
    );
};

export default UpdateBannerSectionTwo;
