import React, { useEffect, useState } from "react";
import slugify from "slugify";
import HeaderBottom from "../../components/HomePage/HeaderBottom";
import MarginTop from "../../MarginTop";
import { Link } from "react-router-dom";

const AddPage = () => {
  const [pageName, setPageName] = useState("");
  const [pageSEOTitle, setPageSEOTitle] = useState("");
  const [pageSlug, setPageSlug] = useState("");
  const [pages, setPages] = useState([]); // Store fetched pages

  // Automatically update slug when pageName changes
  useEffect(() => {
    if (pageName) {
      setPageSlug(
        slugify(pageName, {
          lower: true,
          strict: true,
          trim: true,
        })
      );
    }
  }, [pageName]);

  // Fetch pages from the backend
  useEffect(() => {
    fetch("https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page")
      .then((res) => res.json())
      .then((data) => setPages(data));
  }, []);

  const handlePage = (event) => {
    event.preventDefault();
    const pageData = { pageName, pageSEOTitle, pageSlug };

    fetch("https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-page", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(pageData),
    })
      .then((res) => res.json())
      .then(() => {
        alert("Page Added Successfully");
        setPages([...pages, pageData]); // Update state with the new page
      });
  };

  return (
    <>
      <HeaderBottom />

      <div>
        <section id="services" className="services-area d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 text-center">
                <h2 className="mb-5 mt-5">Add New Page</h2>

                <form className="contact-form text-center" onSubmit={handlePage}>
                  <div className="row">
                    {/* Page Name */}
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="Page Name"
                          required
                          value={pageName}
                          onChange={(e) => setPageName(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Page SEO Title */}
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="Page SEO Title"
                          required
                          value={pageSEOTitle}
                          onChange={(e) => setPageSEOTitle(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Auto-generated Slug (Hidden) */}
                    <input type="hidden" value={pageSlug} />

                    {/* Submit Button */}
                    <div className="slider-btn text-center">
                      <button className="btn btn-primary btn-lg">Add Page</button>
                    </div>
                  </div>
                </form>

                {/* Display Pages in a Table */}
                <h3 className="mt-5">Page List</h3>
                <table className="table table-bordered">
                  <thead className="bg-primary text-white">
                    <tr>
                      <th>Sl No</th>
                      <th>Page Name</th>
                      <th>SEO Title</th>

                      <th>Edit/Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pages.map((page, index) => (
                      <tr key={page._id}>
                        <td>{index + 1}</td>
                        <td>{page.pageName}</td>
                        <td>{page.pageSEOTitle}</td>

                        <Link
                          to={`/edit-page/${page.pageSlug}`}
                          className="btn btn-sm btn-warning m-2"
                        >
                          <i className="fas fa-edit"></i> Edit Now
                        </Link>

                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </section>
      </div>

      <MarginTop />
    </>
  );
};

export default AddPage;
