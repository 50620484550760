import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TestimonialSection from './../../components/HomePage/TestimonialSection';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const AddTestimonialOne = () => {
    const [testimonialtext, setTestimonialText] = useState([]);
    const [testimonials, settestimonials] = useState([]);

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonialtext`)
            .then((res) => res.json())
            .then((info) => setTestimonialText(info));
    }, []);

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonials-one`)
            .then((res) => res.json())
            .then((info) => settestimonials(info));
    }, []);

    const handleTestimonialSection = (event) => {
        event.preventDefault();
        const testimonialText = event.target.testimonialText.value;
        const testimonialHeading = event.target.testimonialHeading.value;

        const testimonialSection = {
            testimonialText,
            testimonialHeading,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-testimonial-text`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(testimonialSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Service is Updated');
            });
    };

    const handleStepSection = (event) => {
        event.preventDefault();
        const imageOne = event.target.imageOne.value;
        const personNameOne = event.target.personNameOne.value;
        const personTitleOne = event.target.personTitleOne.value;
        const descOne = event.target.descOne.value;

        const stepSection = {
            imageOne,
            personNameOne,
            personTitleOne,
            descOne,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-testimonial-one`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(stepSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Step Section is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <section id="services" className="services-area mb-100 ">
                <div className='mt-5'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12 text-center">
                                <h3 className='mb-5'>Update Testimonial Text</h3>
                                {testimonialtext.length === 1 &&
                                    <>
                                        {testimonialtext.map(text =>
                                            <Link className='btn btn-primary btn-lg' to={`/testimonialtext-edit-one/${text._id}`}>Update Now</Link>
                                        )}
                                    </>
                                }
                                {testimonialtext.length === 0 &&
                                    <form className="contact-form" onSubmit={handleTestimonialSection}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <input type="text" className="form-control mb-3" name="testimonialText" placeholder="Testimonial Text" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <input type="text" className="form-control mb-3" name="testimonialHeading" placeholder="Testimonial Heading" required />
                                                </div>
                                            </div>
                                            <div className="slider-btn text-center">
                                                <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">Update Testimonial Text</button>
                                            </div>
                                        </div>
                                    </form>
                                }

                                <h3 className='mb-5 mt-5'>Add Testimonial Item</h3>
                                <form className="contact-form d-flex flex-column align-items-center" onSubmit={handleStepSection}>
                                    <div className="row w-100 justify-content-center">
                                        <div className="col-lg-8 mx-auto">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3 " name="imageOne" placeholder="Person Image URL" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 mx-auto">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3 " name="personNameOne" placeholder="Person Name" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 mx-auto">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3 " name="personTitleOne" placeholder="Person Title" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 mx-auto">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3 " name="descOne" placeholder="Details" required />
                                            </div>
                                        </div>
                                        <div className="slider-btn text-center w-100">
                                            <button className="btn btn-primary btn-lg">Add Testimonial</button>
                                        </div>
                                    </div>
                                </form>


                                <div className="container mt-5">
                                    <div className="row justify-content-center">
                                        {testimonials.map(t => (
                                            <div className="col-md-3 mb-4" key={t._id}>
                                                <div className="card h-100 text-center shadow-sm">
                                                    <div className="card-body">
                                                        <div className="testimonial_image mb-3">
                                                            {/* Image content here */}
                                                        </div>
                                                        <h5 className="card-title">{t.personNameOne}</h5>
                                                        <h6 className="card-subtitle mb-2 text-muted">{t.personTitleOne}</h6>
                                                        <p className="card-text">{t.descOne}</p>
                                                        <Link to={`/edit-testimonial-one/${t._id}`} className="btn btn-primary btn-lg">
                                                            Edit Now
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <HeaderBottom></HeaderBottom>
            </section>
        </>
    );
};

export default AddTestimonialOne;
