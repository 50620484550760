import React from "react";
import Banner from "../components/Banner";
import ServicesSection from "../components/HomePage/ServicesSection";
import AboutSection from "../components/HomePage/AboutSection";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import PricingSection from "../components/HomePage/PricingSection";
import ContactUs from "./ContactUs";
import WorkSection from "../components/HomePage/WorkSection";
import FAQSection from "../components/HomePage/FAQSection";
import CallToAction from "../components/HomePage/CallToAction";
import BannerFive from "../components/BannerFive";
import ServicesSectionFive from "../components/HomePage/ServicesSectionFive";
import AboutSectionFive from "../components/HomePage/AboutSectionFive";
import FAQSectionFive from "../components/HomePage/FAQSectionFive";
import TestimonialSectioFive from "../components/HomePage/TestimonialSectioFive";





const HomeFive = () => {

  return (
    <div className="boxed_wrapper ltr">
      <BannerFive></BannerFive>
      <ServicesSectionFive></ServicesSectionFive>
      <AboutSectionFive></AboutSectionFive>
      <FAQSectionFive></FAQSectionFive>
      <TestimonialSectioFive></TestimonialSectioFive>
      <CallToAction></CallToAction>




    </div>
  );
};

export default HomeFive;
