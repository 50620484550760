import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateAboutSectionThree = () => {

    const [about, setAbout] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/about-three/${id}`)
            .then((res) => res.json())
            .then((info) => setAbout(info));
    }, [id]);

    const handleAboutSection = (event) => {
        event.preventDefault();

        const aboutImgOneThree = event.target.aboutImgOneThree.value;
        const aboutHeadingThree = event.target.aboutHeadingThree.value;
        const aboutDetailsThree = event.target.aboutDetailsThree.value;
        const aboutTextThree = event.target.aboutTextThree.value;
        const pointOneThree = event.target.pointOneThree.value;
        const pointOneTextThree = event.target.pointOneTextThree.value;
        const pointTwoThree = event.target.pointTwoThree.value;
        const pointTwoTextThree = event.target.pointTwoTextThree.value;

        const aboutSection = {
            aboutImgOneThree,
            aboutHeadingThree,
            aboutDetailsThree,
            aboutTextThree,
            pointOneThree,
            pointOneTextThree,
            pointTwoThree,
            pointTwoTextThree,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-about-three/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(aboutSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('About is Updated');
            });
    };

    return (
        <>

        <HeaderBottom></HeaderBottom>
            
            <section id="services" className="services-area vh-100 d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 text-center">


                            <form className="contact-form" onSubmit={handleAboutSection}>
                                <h2 className='mb-5'>Update About</h2>
                                <div className="row justify-content-center">

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutImgOneThree" defaultValue={about.aboutImgOneThree} placeholder="Image One" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutHeadingThree" defaultValue={about.aboutHeadingThree} placeholder="About Heading" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutDetailsThree" defaultValue={about.aboutDetailsThree} placeholder="About Details" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutTextThree" defaultValue={about.aboutTextThree} placeholder="About Text" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOneThree" defaultValue={about.pointOneThree} placeholder="Point One" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOneTextThree" defaultValue={about.pointOneTextThree} placeholder="Point One Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwoThree" defaultValue={about.pointTwoThree} placeholder="Point Two" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwoTextThree" defaultValue={about.pointTwoTextThree} placeholder="Point Two Text" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 text-center">
                                        <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">Update About </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UpdateAboutSectionThree;
