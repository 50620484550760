import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const AboutSection = () => {
    const [about, setAbout] = useState([]);
    const [page, setPage] = useState([]);
    const [selectedSlug, setSelectedSlug] = useState(""); // State to store pageSlug

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/about`)
            .then((res) => res.json())
            .then((info) => setAbout(info));
    }, []);

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
            .then((res) => res.json())
            .then((info) => setPage(info));
    }, []);

    const handlePageChange = (e) => {
        const selectedPage = page.find((p) => p.pageName === e.target.value);
        setSelectedSlug(selectedPage ? selectedPage.pageSlug : ""); // Store selectedSlug
    };

    const handleAboutSection = (event) => {
        event.preventDefault();

        const pageName = event.target.pageName.value;
        const aboutImgOne = event.target.aboutImgOne.value;
        const aboutHeading = event.target.aboutHeading.value;
        const aboutText = event.target.aboutText.value;
        const pointOne = event.target.pointOne.value;
        const pointOneText = event.target.pointOneText.value;
        const pointTwo = event.target.pointTwo.value;
        const pointTwoText = event.target.pointTwoText.value;
        const pointThree = event.target.pointThree.value;
        const pointThreeText = event.target.pointThreeText.value;
        const pointFour = event.target.pointFour.value;
        const pointFourText = event.target.pointFourText.value;

        const aboutSection = {
            pageName,
            pageSlug: selectedSlug, // Corrected - adding pageSlug here
            aboutImgOne,
            aboutHeading,
            aboutText,
            pointOne,
            pointOneText,
            pointTwo,
            pointTwoText,
            pointThree,
            pointThreeText,
            pointFour,
            pointFourText,
        };

        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-about`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(aboutSection),
        })
            .then((res) => res.json())
            .then(() => {
                alert('About is Updated');
            });
    };

    return (
        <>
            <HeaderBottom />

            <section className="services-area d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 text-center">
                            {/* Display Pages in a Table */}
                            <h3 className="mt-5">Page List</h3>
                            <table className="table table-bordered">
                                <thead className="bg-primary text-white">
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Page Name</th>
                                        <th>Heading About</th>
                                        <th>Edit/Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {about.map((page, index) => (
                                        <tr key={page._id}>
                                            <td>{index + 1}</td>
                                            <td>{page.pageName}</td>
                                            <td>{page.aboutHeading}</td>
                                            <td><td><Link to={`/about/${page._id}`} className="btn btn-sm btn-warning">
                                                <i className="fas fa-edit"></i>  Edit Now
                                            </Link></td></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                            <form className="contact-form" onSubmit={handleAboutSection}>
                                <div className="row">
                                    <div className="text-black">
                                        <select
                                            name="pageName"
                                            className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                                            required
                                            style={{ color: 'black' }}
                                            onChange={handlePageChange}
                                        >
                                            <option value="" disabled selected>
                                                Select Page
                                            </option>
                                            {page.map((p) => (
                                                <option key={p._id} value={p.pageName}>
                                                    {p.pageName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Display the selected pageSlug in an input */}
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={selectedSlug} // Set value from state
                                                readOnly
                                                placeholder="Page Slug"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutImgOne" placeholder="About Image URL" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutHeading" placeholder="About Heading" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutText" placeholder="About Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOne" placeholder="Point One" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOneText" placeholder="Point One Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwo" placeholder="Point Two" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwoText" placeholder="Point Two Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointThree" placeholder="Point Three " required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointThreeText" placeholder="Point Three Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointFour" placeholder="Point Four" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointFourText" placeholder="Point Four Text" required />
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button className="btn btn-primary btn-lg" type="submit"> Update About </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <MarginTop />
        </>
    );
};

export default AboutSection;
