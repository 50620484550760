import React from "react";
import CallToAction from "../components/HomePage/CallToAction";
import BannerOne from "../components/BannerOne";
import ServicesSectionOne from "../components/HomePage/ServicesSectionOne";
import AboutSectionOne from "../components/HomePage/AboutSectionOne";
import FAQSectionOne from "../components/HomePage/FAQSectionOne";
import TestimonialSectionOne from "../components/HomePage/TestimonialSectionOne";





const HomeOne = () => {

    return (
        <div className="boxed_wrapper ltr">

            <BannerOne></BannerOne>
            <ServicesSectionOne></ServicesSectionOne>
            <AboutSectionOne></AboutSectionOne>
            <FAQSectionOne></FAQSectionOne>
            <TestimonialSectionOne></TestimonialSectionOne>
            <CallToAction></CallToAction>
        </div>
    );
};

export default HomeOne;
