import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [page, setPage] = useState([]);
  const { pageSlug } = useParams(); // Extract the blog post titleSlug from the URL

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page/${pageSlug}`)
      .then((res) => res.json())
      .then((info) => setPage(info))
      .catch((error) => console.error("Error fetching banners:", error));
  }, []);

  // Fetch banners data
  useEffect(() => {
    fetch('https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner')
      .then((res) => res.json())
      .then((info) => setBanners(info))
      .catch((error) => console.error("Error fetching banners:", error));
  }, []);

  // Fetch blog post data when pageSlug changes


  return (

    <>

      {
        banners.map(b => b.pageSlug === page.pageSlug &&

          <div
            className="welocme1-section-area"
            style={{
              backgroundImage: "url(assets/images/background/header-bg1.png)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-10 m-auto">
                  <div className="welcome-content-header heading1">
                    <div className="elementors-img">
                      <img
                        src="assets/images/elements/header-element1.png"
                        alt=""
                        className="keyframe4"
                      />
                    </div>
                    <h1 className="text-anime">
                      {b.bannerHeading}
                    </h1>
                    <p data-aos="fade-up" data-aos-duration={1200} className="text-break text-wrap">
                      {b.bannerDetails.split(" ").reduce((acc, word, index) => {
                        if ((index + 1) % 12 === 0) {
                          acc.push(<span key={index}>{word} <br /></span>);
                        } else {
                          acc.push(<span key={index}>{word} </span>);
                        }
                        return acc;
                      }, [])}
                    </p>
                    <div
                      className="welcome-btnarea text-center"
                      data-aos="fade-up"
                      data-aos-duration={1400}
                    >
                      <a href={b.buttonURL} className="header-btn">
                        {b.buttonText} <i className="fa-solid fa-arrow-right" />
                      </a>
                      <a href={b.buttonURLTwo} className="header-btn1">
                        {b.buttonTextTwo} <i className="fa-solid fa-arrow-right" />
                      </a>
                      <div className="mt-5">
                        <img
                          src={b.imageOne}
                          alt=""

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        )
      }
    </>
  );
};

export default Banner;
