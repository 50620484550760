import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);
  const [page, setPage] = useState([]);
    const { pageSlug } = useParams(); // Extract the blog post titleSlug from the URL

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);

   useEffect(() => {
      fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page/${pageSlug}`)
        .then((res) => res.json())
        .then((info) => setPage(info))
        .catch((error) => console.error("Error fetching banners:", error));
    }, []);





  return (



    <>
      {
        about.map(b => b.pageSlug === page.pageSlug &&

          <div className="service1-section-area sp3" id="about-sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 m-auto">
                  <div className="service1-header heading2 text-center">
                    <span data-aos="fade-up" data-aos-duration={800}>
                    {b.aboutText}
                    </span>
                    <h2 data-aos="fade-up" data-aos-duration={1000}>
                    {b.aboutHeading}
                    </h2>
                  </div>
                </div>
                <div className="space60" />
              </div>
              <div className="service-vertical-tabs">
                <img
                  src="assets/images/elements/tabs-elements1.png"
                  alt=""
                  className="tabs-elements keyframe4"
                />
                <div className="grid">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="service-tabs-grid">
                        <div className="menu">
                          <div className="active">
                            <span className="light" />
                            <div
                              className="tabs-content-area"
                              data-aos="fade-right"
                              data-aos-duration={800}
                            >
                              <div className="tabs-btn-icon">
                                <img src="assets/images/icons/tabs-btn1.svg" alt="" />
                              </div>
                              <div className="tabs-btn-content">
                                <h4>{b.pointOne}</h4>
                                <p>{b.pointOneText}</p>
                              </div>
                            </div>
                          </div>
                          <div className="space16" />
                          <div>
                            <span className="light" />
                            <div
                              className="tabs-content-area"
                              data-aos="fade-right"
                              data-aos-duration={1000}
                            >
                              <div className="tabs-btn-icon">
                                <img src="assets/images/icons/tabs-btn2.svg" alt="" />
                              </div>
                              <div className="tabs-btn-content">
                                <h4>{b.pointTwo}</h4>
                                <p>{b.pointTwoText}</p>
                              </div>
                            </div>
                          </div>
                          <div className="space16" />
                          <div>
                            <span className="light" />
                            <div
                              className="tabs-content-area"
                              data-aos="fade-right"
                              data-aos-duration={1200}
                            >
                              <div className="tabs-btn-icon">
                                <img src="assets/images/icons/tabs-btn3.svg" alt="" />
                              </div>
                              <div className="tabs-btn-content">
                                <h4>{b.pointThree}</h4>
                                <p>{b.pointThreeText}</p>
                              </div>
                            </div>
                          </div>
                          <div className="space16" />
                          <div>
                            <span className="light" />
                            <div
                              className="tabs-content-area"
                              data-aos="fade-right"
                              data-aos-duration={1400}
                            >
                              <div className="tabs-btn-icon">
                                <img src="assets/images/icons/tabs-btn4.svg" alt="" />
                              </div>
                              <div className="tabs-btn-content">
                                <h4>{b.pointFour}</h4>
                                <p>{b.pointFourText}</p>
                              </div>
                            </div>
                          </div>
                          <div className="space16" />
                          <div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div
                        className="service-tabs-grid"
                        data-aos="zoom-out"
                        data-aos-duration={1200}
                      >
                        <ul className="">
                          <li className="active">
                            <div className="">
                              <img
                                src={b.aboutImgOne}
                                alt=""
                              />
                              
                            </div>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        )
      }



    </>
  );
};

export default AboutSection;
