import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';


const AddCategory = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to generate a slug from a string
  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '') // Remove invalid characters
      .replace(/\s+/g, '-') // Replace spaces with dashes
      .trim();
  };

  // Handle category submission
  const handleCategorySection = (event) => {
    event.preventDefault();

    const categoryName = event.target.categoryName.value;
    const categoryImage = event.target.categoryImage.value;

    // Generate the slug from the category name
    const categorySlug = generateSlug(categoryName);

    const categorySection = {
      categoryName,

      categoryImage,

      categorySlug, // Add the slug to the category object
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-category`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categorySection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Your Category has been added successfully!');
        // Navigate to the admin categories page after successful submission
        navigate('/admin/category');
      });
  };

  return (
    <>
      <HeaderBottom />
      <MarginTop></MarginTop>
      <div>
        <section id="services" className="services-area flex items-center py-12 vh-100">
          <div className="container mx-auto">
            <div className="flex justify-center">
              <div className="col-lg-12 text-center"> {/* Added text-center to center content inside this div */}
                <h2 className="text-3xl font-bold text-gray-800 mb-8">Add Category</h2>

                <form className="space-y-6" onSubmit={handleCategorySection}>
                  <div>
                    <input
                      type="text"
                      className="form-control w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none text-black mb-3"
                      name="categoryName"
                      placeholder="Category Name"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none text-black mb-3"
                      name="categoryImage"
                      placeholder="Category Image URL"
                      required
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary btn-lg"
                      type="submit"
                    >
                      Add Category
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>


    </>
  );
};

export default AddCategory;