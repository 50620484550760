import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditFeaturesServicesText = () => {


    const [featuresone, setFeaturesText] = useState([]);

    const { id } = useParams();


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featurestext/${id}`)
            .then((res) => res.json())
            .then((info) => setFeaturesText(info));
    }, []);



    const handleFeaturesTextSection = (event) => {
        event.preventDefault();
        const FeaturesText = event.target.FeaturesText.value;
        const FeaturesHeading = event.target.FeaturesHeading.value;


        const featureSection = {
            FeaturesText,
            FeaturesHeading,

        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featurestext/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(featureSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Feature Text Is Updated');
            });
    };








    return (
        <>
            <HeaderBottom />
            <section id="services" className="services-area vh-100 d-flex justify-content-center align-items-center text-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 d-flex flex-column align-items-center">
                            <h3>Update Features Text</h3>
                            <form className="contact-form w-100" onSubmit={handleFeaturesTextSection}>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-name mb-3">
                                            <input
                                                className="form-control text-center"
                                                name="FeaturesText"
                                                defaultValue={featuresone.FeaturesText}
                                                placeholder="Features Text"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-name mb-3">
                                            <input
                                                className="form-control text-center"
                                                name="FeaturesHeading"
                                                defaultValue={featuresone.FeaturesHeading}
                                                placeholder="Features Heading"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="slider-btn">
                                        <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">
                                            Update Features Text
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>


    );
};

export default EditFeaturesServicesText;