import React, { useEffect, useState } from "react";

const TestimonialSectionForHome = () => {
    const [testimonial, setTestimonial] = useState([]);

    const [testimonialtext, setTestimonialText] = useState([]);

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonialtext`)
            .then((res) => res.json())
            .then((info) => setTestimonialText(info));
    }, []);

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonials`)
            .then((res) => res.json())
            .then((info) => setTestimonial(info));
    }, []);


    const divStyle = {
        backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#fff7f5",
    };

    return (



        <>

            <div className="testimonial1-section-area sp3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            {
                                testimonialtext.map(e => e.pageSlug === '' && <div className="testimonial1-header text-center heading2">
                                    <span data-aos="fade-up" data-aos-duration={800}>
                                        {e.testimonialText}
                                    </span>
                                    <h2
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                        className="text-capitalize"
                                    >
                                        {e.testimonialHeading}
                                    </h2>
                                </div>)}
                        </div>
                    </div>
                    <div className="space60" />
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="testimonial-sliders">
                                <div className="row align-items-center">
                                    {
                                        testimonial.map(b => b.pageSlug === '' && <div className="col-lg-4">
                                            <div className="testimonial-content-slider slider-nav1">
                                                <div className="testimonial-slider-boxarea">
                                                    <div className="testimonila-img">
                                                        <div className="mans-slider-info">
                                                            <img
                                                                src={b.image}
                                                                alt=""
                                                            />
                                                            <div className="content">
                                                                <a href="/">{b.personName}</a>
                                                                <p>{b.personTitle}</p>
                                                            </div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <a href="">
                                                                    <i className="fa-solid fa-star" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="">
                                                                    <i className="fa-solid fa-star" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="">
                                                                    <i className="fa-solid fa-star" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="">
                                                                    <i className="fa-solid fa-star" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="">
                                                                    <i className="fa-solid fa-star" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="testimonial5-all-content">
                                                        <p>
                                                            {b.desc}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>



    );
};

export default TestimonialSectionForHome;
