import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditPage = () => {
    const [page, setPage] = useState(null); // Initial state as null to handle loading state
    const { id, pageSlug } = useParams(); // Extract both id and pageSlug from the URL

    useEffect(() => {
        // Fetch data using pageSlug from the URL
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page/${pageSlug}`)
            .then((res) => res.json())
            .then((info) => setPage(info))
            .catch((error) => console.error('Error fetching page:', error));
    }, [pageSlug]); // Only re-fetch when pageSlug changes

    const handlePageSection = (event) => {
        event.preventDefault();
        const pageName = event.target.pageName.value;
        const pageSEOTitle = event.target.pageSEOTitle.value;
        const pageSlug = event.target.pageSlug.value;

        const pageSection = {
            pageName,
            pageSEOTitle,
            pageSlug,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-page/${pageSlug}`;
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pageSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Page is Updated');
            })
            .catch((error) => console.error('Error updating page:', error));
    };

    if (!page) {
        return <div>Loading...</div>; // Show loading message while fetching data
    }

    return (
        <>
            <HeaderBottom />
            <div>
                <section id="services" className="services-area vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h2 className="mb-5 mt-5">Update Page</h2>
                                <form className="contact-form" onSubmit={handlePageSection}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="pageName"
                                                    defaultValue={page.pageName || ''}
                                                    placeholder="Page Name"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="pageSlug"
                                                    defaultValue={page.pageSlug || ''}
                                                    placeholder="Page Name"
                                                   
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="pageSEOTitle"
                                                    defaultValue={page.pageSEOTitle || ''}
                                                    placeholder="Page SEO Title"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 text-center">
                                            <button className="btn btn-primary btn-lg mt-4">Update Page</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default EditPage;
