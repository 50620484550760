import React from "react";
import Banner from "../components/Banner";
import ServicesSection from "../components/HomePage/ServicesSection";
import AboutSection from "../components/HomePage/AboutSection";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import PricingSection from "../components/HomePage/PricingSection";
import ContactUs from "./ContactUs";
import WorkSection from "../components/HomePage/WorkSection";
import FAQSection from "../components/HomePage/FAQSection";
import CallToAction from "../components/HomePage/CallToAction";
import BannerTwo from "../components/BannerTwo";
import ServicesSectionTwo from "../components/HomePage/ServicesSectionTwo";
import AboutSectionTwo from "../components/HomePage/AboutSectionTwo";
import FAQSectionTwo from "../components/HomePage/FAQSectionTwo";
import TestimonialSectionTwo from "../components/HomePage/TestimonialSectionTwo";





const HomeTwo = () => {

  return (
    <div className="boxed_wrapper ltr">

      <BannerTwo></BannerTwo>
      <ServicesSectionTwo></ServicesSectionTwo>
      <AboutSectionTwo></AboutSectionTwo>
      <FAQSectionTwo></FAQSectionTwo>
      <TestimonialSectionTwo></TestimonialSectionTwo>
      <CallToAction></CallToAction>




    </div>
  );
};

export default HomeTwo;
