import React from "react";
import Banner from "../components/Banner";
import ServicesSection from "../components/HomePage/ServicesSection";
import AboutSection from "../components/HomePage/AboutSection";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import PricingSection from "../components/HomePage/PricingSection";
import ContactUs from "./ContactUs";
import WorkSection from "../components/HomePage/WorkSection";
import FAQSection from "../components/HomePage/FAQSection";
import CallToAction from "../components/HomePage/CallToAction";
import BannerThree from "../components/BannerThree";
import ServicesSectionThree from "../components/HomePage/ServicesSectionThree";
import AboutSectionThree from "../components/HomePage/AboutSectionThree";
import FAQSectionThree from "../components/HomePage/FAQSectionThree";
import TestimonialSectionThree from "../components/HomePage/TestimonialSectionThree";



const HomeThree = () => {

  return (
    <div className="boxed_wrapper ltr">

      <BannerThree></BannerThree>
      <ServicesSectionThree></ServicesSectionThree>
      <AboutSectionThree></AboutSectionThree>
      <FAQSectionThree></FAQSectionThree>
      <TestimonialSectionThree></TestimonialSectionThree>
      <CallToAction></CallToAction>




    </div>
  );
};

export default HomeThree;
