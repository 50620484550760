import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const UpdateAboutSection = () => {

    const [about, setAbout] = useState({});
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/about-us/${id}`)
            .then((res) => res.json())
            .then((info) => setAbout(info));
    }, [id]);

    const handleAboutSection = (event) => {
        event.preventDefault();

        const pageName = event.target.pageName.value;
        const aboutImgOne = event.target.aboutImgOne.value;
        const pageSlug = event.target.pageSlug.value;
        const aboutHeading = event.target.aboutHeading.value;
        const aboutText = event.target.aboutText.value;
        const pointOne = event.target.pointOne.value;
        const pointOneText = event.target.pointOneText.value;
        const pointTwo = event.target.pointTwo.value;
        const pointTwoText = event.target.pointTwoText.value;
        const pointThree = event.target.pointThree.value;
        const pointThreeText = event.target.pointThreeText.value;
        const pointFour = event.target.pointFour.value;
        const pointFourText = event.target.pointFourText.value;

        const aboutSection = {
            pageName,
            pageSlug,
            aboutImgOne,
            aboutHeading,
            aboutText,
            pointOne,
            pointOneText,
            pointTwo,
            pointTwoText,
            pointThree,
            pointThreeText,
            pointFour,
            pointFourText,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-about/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(aboutSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('About is Updated');
            });
    };

    if (!about.pageName) {
        return <div>Loading...</div>;
    }

    return (
        <>
        <HeaderBottom></HeaderBottom>
        <MarginTop></MarginTop>
            <section id="services" className="services-area  d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 text-center">
                            <form className="contact-form" onSubmit={handleAboutSection}>
                                <h2 className='mb-5'>Update About</h2>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pageName" defaultValue={about.pageName} placeholder="Page Name" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pageSlug" defaultValue={about.pageSlug} placeholder="Page Slug"  />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutImgOne" defaultValue={about.aboutImgOne} placeholder="Image One" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutHeading" defaultValue={about.aboutHeading} placeholder="About Heading" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="aboutText" defaultValue={about.aboutText} placeholder="About Text" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOne" defaultValue={about.pointOne} placeholder="Point One" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointOneText" defaultValue={about.pointOneText} placeholder="Point One Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwo" defaultValue={about.pointTwo} placeholder="Point Two" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointTwoText" defaultValue={about.pointTwoText} placeholder="Point Two Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointThree" defaultValue={about.pointThree} placeholder="Point Two Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointThreeText" defaultValue={about.pointThreeText} placeholder="Point Two Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointFour" defaultValue={about.pointFour} placeholder="Point Two Text" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pointFourText" defaultValue={about.pointFourText} placeholder="Point Two Text" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 text-center">
                                        <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">Update About</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
               
            </section>
            <MarginTop></MarginTop>
        </>
    );
};

export default UpdateAboutSection;
