import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PricingSection.css';


const PricingSectionForHome = () => {

    const [pricing, setPricing] = useState([]);


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/pricings`)
            .then((res) => res.json())
            .then((info) => setPricing(info));
    }, []);

    return (





        <>
          
            {/*===== Pricing Plan Start =======*/}

            <div className="pricing-paln-section-area pricing2 sp6 bg-none" id='pricing-sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 m-auto">
                            <div className="pricing-header text-center heading6">
                                <span className="seo">Pricing Plan</span>
                                <div className="space16" />
                                <h2 className="text-capitalize">
                                    {" "}
                                    Affordable Pricing
                                    Plans
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="space60" />
                    <div className="pricing-plans">
                        <div className="container">

                            <div className="tab-content">
                                <div id="monthly" style={{ display: "block" }}>
                                    <div className="row">

                                        {
                                            pricing.map(b => b.pageSlug === '' && <div className="col-md-6 col-lg-4">
                                                <div className="price-box">
                                                    <div className="hadding">
                                                        <div className="pricing-area bg2">
                                                            <h3>{b.packageName} Plan</h3>
                                                            <div className="space24" />
                                                            <h2 className="pricing-heading">
                                                                ${b.packagePrice}<span></span>
                                                            </h2>
                                                            <div className="space16" />

                                                            <div className="space24" />
                                                            <div>
                                                                <Link to={`/order-now/${b._id}`} className="header-btn2">{b.packageButtonText}</Link>
                                                            </div>
                                                        </div>
                                                        <div className="price-list">
                                                            <ul>
                                                                <li>
                                                                    <img
                                                                        src="assets/images/icons/check-icons4.svg"
                                                                        alt=""
                                                                    />{" "}
                                                                    {b.packagePointOne}
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src="assets/images/icons/check-icons4.svg"
                                                                        alt=""
                                                                    />{" "}
                                                                    {b.packagePointTwo}
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src="assets/images/icons/check-icons4.svg"
                                                                        alt=""
                                                                    />{" "}
                                                                    {b.packagePointThree}
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src="assets/images/icons/check-icons4.svg"
                                                                        alt=""
                                                                    />{" "}
                                                                    {b.packagePointFour}
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src="assets/images/icons/check-icons4.svg"
                                                                        alt=""
                                                                    />{" "}
                                                                    {b.packagePointFive}
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src="assets/images/icons/check-icons4.svg"
                                                                        alt=""
                                                                    />{" "}
                                                                    {b.packagePointSix}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*===== Pricing Plan  End=======*/}

        </>
    );
};

export default PricingSectionForHome;