import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateBannerSectionFive = () => {
    const [banner, setBanner] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner-five/${id}`)
            .then((res) => res.json())
            .then((info) => setBanner(info));
    }, [banner]);

    const handleBannerSection = (event) => {
        event.preventDefault();
        const bannerHeadingFive = event.target.bannerHeadingFive.value;
        const bannerDetailsFive = event.target.bannerDetailsFive.value;
        const bannerTextFive = event.target.bannerTextFive.value;
        const imageOneFive = event.target.imageOneFive.value;
        const buttonTextFive = event.target.buttonTextFive.value;
        const buttonURLFive = event.target.buttonURLFive.value;
        const buttonTextTwoFive = event.target.buttonTextTwoFive.value;
        const bannerTextTwoFive = event.target.bannerTextTwoFive.value;

        const bannerSection = {
            bannerHeadingFive,
            bannerDetailsFive,
            bannerTextFive,
            imageOneFive,
            buttonTextFive,
            buttonURLFive,
            buttonTextTwoFive,
            bannerTextTwoFive,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-banner-five/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bannerSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Banner is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <div>
                <section id="services" className="services-area vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h2 className='mb-5 mt-5'>Update Banner</h2>
                                <form className="contact-form" onSubmit={handleBannerSection}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingFive" defaultValue={banner.bannerHeadingFive} placeholder="Banner Heading One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextTwoFive" defaultValue={banner.bannerTextTwoFive} placeholder="Banner Heading Two" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextFive" defaultValue={banner.bannerTextFive} placeholder="Banner Heading Three" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerDetailsFive" defaultValue={banner.bannerDetailsFive} placeholder="Banner Details " required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="imageOneFive" defaultValue={banner.imageOneFive} placeholder="Image One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextFive" defaultValue={banner.buttonTextFive} placeholder="Button Text" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 ">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextTwoFive" defaultValue={banner.buttonTextTwoFive} placeholder="Reviews Text" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonURLFive" defaultValue={banner.buttonURLFive} placeholder="Button URL" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-center">
                                            <button className="btn btn-primary btn-lg mt-4">Update Banner</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <HeaderBottom />
            </div>
        </>
    );
};

export default UpdateBannerSectionFive;
