import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createPopper } from '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Bootstrap's JS includes Popper.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';








const NavBar = () => {
  const [user] = useAuthState(auth);
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate('/'); // Navigate to the home page after successful sign-out
      })
      .catch((error) => {
        console.error('Sign out error: ', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logoResponse = await fetch("https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/logo");
        const logoData = await logoResponse.json();
        setLogo(logoData);

        const usersResponse = await fetch("https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/users");
        const usersData = await usersResponse.json();
        setUsers(usersData);

        const profileResponse = await fetch("https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/profiles/");
        const profileData = await profileResponse.json();
        setProfile(profileData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };



  const button = document.querySelector('#button');
  const tooltip = document.querySelector('#tooltip');

  const popperInstance = createPopper(button, tooltip, {
    placement: 'right', // Adjust the position as needed
  });


  return (



    <>
      {/*===== PAGE PROGRESS START=======*/}
      <div className="paginacontainer">
        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      </div>
      {/*===== PAGE PROGRESS END=======*/}
      {/*=====HEADER START=======*/}
      <header>
        <div
          className="header-area homepage1 header header-sticky d-none d-lg-block "
          id="header"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="header-elements">
                  {
                    logo.map(l => <div className="site-logo">
                      <a href="/">
                        <img src={l.logo} alt="" />
                      </a>
                    </div>)
                  }
                  <div className="main-menu">
                    <ul>
                      <li>
                        <a href="/">
                          Home <i className="fa-solid fa-angle-down" />
                        </a>
                        
                      </li>
                      <li>
                        <a href="#services-sec">Services</a>
                      </li>

                      <li>
                        <a href="#about-sec">About</a>
                      </li>
                      <li>
                        <a href="#pricing-sec">Pricing</a>
                      </li>
                      {/* <li>
                        <a href="/blog">Blog</a>
                      </li> */}
                      <li>
                        <a href="#contact-sec">Contact</a>
                      </li>
                    </ul>
                  </div>
                  <div className="cta-btn d-none d-md-block">
                    <div className="menu-right-content d-flex">
                      {user?.email ? (
                        <>
                          {profile.map(
                            (pro) =>
                              pro.userEmail === user?.email && (
                                <div className="nav-item dropdown mt-1 me-3" key={pro._id}>
                                  <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"  // ✅ Bootstrap 5 fix
                                    aria-expanded="false"
                                  >
                                    <img
                                      src={
                                        pro.profileImg ||
                                        "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                      }
                                      alt="Profile"
                                      className="rounded-circle me-2"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-end p-3 mt-3">
                                    <li>
                                      <Link className="dropdown-item" to="/dashboard">
                                        <i className="fa-solid fa-bars text-primary"></i> Dashboard
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to="/my-order/">
                                        <i className="fa-solid fa-cart-shopping text-primary"></i> My Orders
                                      </Link>
                                    </li>
                                    <li>
                                      <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-user text-primary"></i> {pro.userName}
                                    </li>
                                    <li>{pro.userEmail}</li>
                                    <Link to={`/update-profile/${pro._id}`} className="mt-2 mb-2">
                                      Edit Profile
                                      <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
                                    </Link>
                                    <li>
                                      <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                      <button className="dropdown-item" onClick={handleSignOut}>
                                        <i className="fa-solid fa-right-from-bracket text-primary"></i> Logout
                                      </button>
                                    </li>
                                  </ul>
                                </div>

                              )
                          )}
                        </>
                      ) : (
                        <ul className="list-unstyled">
                          <li className="nav-item d-flex align-items-center">
                            <Link to="/login" className="header-btn mt-3">
                              Log in
                            </Link>
                          </li>
                        </ul>

                      )}


                      <div className="side-item">
                        {users.map(
                          (u, index) =>
                            u.userEmail === user?.email &&
                            u.userRole === "Admin" && (
                              <li className="nav-item d-flex align-items-center" key={index}>
                                <Link to="/admin" className="header-btn ">
                                  Admin
                                </Link>
                              </li>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/*=====HEADER END =======*/}
      {/*===== MOBILE HEADER STARTS =======*/}
      <div className="mobile-header mobile-haeder1 d-block d-lg-none">
        <div className="container-fluid">
          <div className="col-12">
            <div className="mobile-header-elements">
              <div className="mobile-logo">
                {
                  logo.map(l => <a href="/">
                    <img src={l.logo} alt="" />
                  </a>)
                }

              </div>
              <div className="mobile-nav-icon dots-menu">
                <i className="fa-solid fa-bars" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mobile-sidebar mobile-sidebar1"
        style={{
          backgroundImage: "url(assets/images/background/header-bg1.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <div className="logosicon-area">
          {
            logo.map(l => <div className="logos">
              <img src={l.logo} alt="" />
            </div>)
          }
          <div className="menu-close">
            <i className="fa-solid fa-xmark" />
          </div>
        </div>
        <div className="mobile-nav">
          <ul className="mobile-nav-list">
            <li>
              <a href="/">Home </a>

            </li>
            <li>
              <a href="#about-sec">About</a>
            </li>
            <li>
              <a href="#services-sec">Services</a>
            </li>
            <li>
              <a href="#pricing-sec">Pricing</a>
            </li>
            {/* <li>
              <a href="/blog">Blog</a>
            </li> */}


            <li>
              <a href="#contact-sec">Contact</a>
            </li>
          </ul>
          <div className="allmobilesection">
            <div className="menu-right-content d-flex">
              {user?.email ? (
                <>
                  {profile.map(
                    (pro) =>
                      pro.userEmail === user?.email && (
                        <div className="nav-item dropdown mt-1 me-3" key={pro._id}>
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"  // ✅ Bootstrap 5 fix
                            aria-expanded="false"
                          >
                            <img
                              src={
                                pro.profileImg ||
                                "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                              }
                              alt="Profile"
                              className="rounded-circle me-2"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                              }}
                            />
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end p-3 mt-3">
                            <li>
                              <a className="dropdown-item" href="/dashboard">
                                <i className="fa-solid fa-bars text-primary"></i> Dashboard
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="/my-order/">
                                <i className="fa-solid fa-cart-shopping text-primary"></i> My Orders
                              </a>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <i className="fa-solid fa-user text-primary"></i> {pro.userName}
                            </li>
                            <li>{pro.userEmail}</li>
                            <a href={`/update-profile/${pro._id}`} className="mt-2 mb-2">
                              Edit Profile
                              <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
                            </a>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <button className="dropdown-item" onClick={handleSignOut}>
                                <i className="fa-solid fa-right-from-bracket text-primary"></i> Logout
                              </button>
                            </li>
                          </ul>

                        </div>

                      )
                  )}
                </>
              ) : (
                <ul className="list-unstyled">
                  <li className="nav-item d-flex align-items-center">
                    <Link to="/login" className="header-btn mt-3 mobile-get " target="_blank" rel="noopener noreferrer">
                      Log in
                    </Link>
                  </li>
                </ul>

              )}


              <div className="side-item">
                {users.map(
                  (u, index) =>
                    u.userEmail === user?.email &&
                    u.userRole === "Admin" && (
                      <li className="nav-item d-flex align-items-center" key={index}>
                        <a href="/admin" className="btn sm text-white pl-3 pr-3" target="_blank" rel="noopener noreferrer">
                          Admin
                        </a>
                      </li>

                    )
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
      {/*===== MOBILE HEADER STARTS =======*/}
    </>


  );
};

export default NavBar;
