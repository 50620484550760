import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const BannerTwo = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner-two`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (



    <>

      {
        banners.map(b => <section className="banner-style-six">
          <div className="pattern-layer">
            <div className="pattern-1" style={{ backgroundImage: 'url(assets/images/shape/shape-49.png)' }} />
            <div className="pattern-2" style={{ backgroundImage: 'url(assets/images/shape/shape-50.png)' }} />
          </div>

          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content-box ml_30">
                  <h2>{b.bannerHeadingTwo} <span>{b.bannerTextTwo}</span> {b.bannerTextTwo}</h2>
                  <p>{b.bannerDetailsTwo}</p>
                  <div className="btn-box mb_40">
                    <a href={b.buttonURLTwo} className="btn btn-primary btn-lg mr_30">{b.buttonTextTwo}</a>


                  </div>
                  <div className="customer-review">
                    <ul className="image-box clearfix">
                      <li><img src="https://i.ibb.co.com/zWdppvgy/customer-1.jpg" alt /></li>
                      <li><img src="https://i.ibb.co.com/G38XnL1M/customer-4.jpg" alt /></li>
                      <li><img src="https://i.ibb.co.com/d9jbHZT/customer-3.jpg" alt /></li>
                      <li><img src="https://i.ibb.co.com/Y7dmYBGW/customer-5.jpg" alt /></li>

                    </ul>
                    <ul className="rating-box">
                      <li><i className="icon-19" /></li>
                      <li><i className="icon-19" /></li>
                      <li><i className="icon-19" /></li>
                      <li><i className="icon-19" /></li>
                      <li><i className="icon-19" /></li>
                    </ul>
                    <h6 className="m-3"  >{b.buttonTextTwoTwo}</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image-box ml_40 mr_50">
                  <div className="image-shape">
                    <div className="shape-1" style={{ backgroundImage: 'url(assets/images/shape/shape-48.png)' }} />
                    <div className="shape-2 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-48.png)' }} />
                  </div>
                  <figure className="image"><img src={b.imageOneTwo} alt /></figure>
                </div>
              </div>
            </div>
          </div>
        </section>)
      }



    </>
  );
};

export default BannerTwo;
