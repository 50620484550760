import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TestimonialSection from './../../components/HomePage/TestimonialSection';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const AddTestimonial = () => {
    const [testimonialtext, setTestimonialText] = useState([]);
    const [testimonials, settestimonials] = useState([]);


    const [page, setPage] = useState([]);
    const [selectedSlug, setSelectedSlug] = useState(""); // State to store pageSlug

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
            .then((res) => res.json())
            .then((info) => setPage(info));
    }, []);

    const handlePageChange = (e) => {
        const selectedPage = page.find((p) => p.pageName === e.target.value);
        setSelectedSlug(selectedPage ? selectedPage.pageSlug : ""); // Store selectedSlug
    };

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonialtext`)
            .then((res) => res.json())
            .then((info) => setTestimonialText(info));
    }, []);

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonials`)
            .then((res) => res.json())
            .then((info) => settestimonials(info));
    }, []);

    const handleTestimonialSection = (event) => {
        event.preventDefault();
        const pageName = event.target.pageName.value;
        const testimonialText = event.target.testimonialText.value;
        const testimonialHeading = event.target.testimonialHeading.value;

        const testimonialSection = {
            pageName,
            pageSlug: selectedSlug, // Corrected - adding pageSlug here
            testimonialText,
            testimonialHeading,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-testimonial-text`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(testimonialSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Service is Updated');
            });
    };

    const handleStepSection = (event) => {
        event.preventDefault();
        const pageName = event.target.pageName.value;
        const image = event.target.image.value;
        const personName = event.target.personName.value;
        const personTitle = event.target.personTitle.value;
        const desc = event.target.desc.value;

        const stepSection = {
            pageName,
            pageSlug: selectedSlug, // Corrected - adding pageSlug here
            image,
            personName,
            personTitle,
            desc,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-testimonial`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(stepSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Step Section is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <section id="services" className="services-area mb-100 ">
                <div className='mt-5'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12 text-center">
                                <h3 className='mb-5'>Update Testimonial Text</h3>


                                <table className="table table-bordered">
                                    <thead className="bg-primary text-white">
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Page Name</th>
                                            <th>Heading Testimonial</th>
                                            <th>Edit/Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testimonialtext.map((page, index) => (
                                            <tr key={page._id}>
                                                <td>{index + 1}</td>
                                                <td>{page.pageName}</td>
                                                <td>{page.testimonialHeading}</td>
                                                <td>
                                                    <Link to={`/testimonialtext-edit/${page._id}`} className="btn btn-sm btn-warning">
                                                        <i className="fas fa-edit"></i>  Edit Now
                                                    </Link>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <form className="contact-form" onSubmit={handleTestimonialSection}>
                                    <div className="row">

                                        <div className="text-black">
                                            <select
                                                name="pageName"
                                                className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                                                required
                                                style={{ color: 'black' }}
                                                onChange={handlePageChange}
                                            >
                                                <option value="" disabled selected>
                                                    Select Page
                                                </option>
                                                {page.map((p) => (
                                                    <option key={p._id} value={p.pageName}>
                                                        {p.pageName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Display the selected pageSlug in an input */}
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedSlug} // Set value from state
                                                    readOnly
                                                    placeholder="Page Slug"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input type="text" className="form-control mb-3" name="testimonialText" placeholder="Testimonial Text" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input type="text" className="form-control mb-3" name="testimonialHeading" placeholder="Testimonial Heading" required />
                                            </div>
                                        </div>
                                        <div className="slider-btn text-center">
                                            <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">Update Testimonial Text</button>
                                        </div>
                                    </div>
                                </form>


                                <h3 className='mb-5 mt-5'>Add Testimonial Item</h3>

                                <table className="table table-bordered">
                                    <thead className="bg-primary text-white">
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Page Name</th>
                                            <th>Heading About</th>
                                            <th>Edit/Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testimonials.map((s, index) => (
                                            <tr key={s._id}>
                                                <td>{index + 1}</td>
                                                <td>{s.pageName}</td>
                                                <td>{s.personTitle}</td>
                                                <td>
                                                    <Link to={`/edit-testimonial/${s._id}`} className="btn btn-sm btn-warning">
                                                        <i className="fas fa-edit"></i>  Edit Now
                                                    </Link>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <form className="contact-form" onSubmit={handleStepSection}>


                                    <div className="row">

                                        <div className="text-black">
                                            <select
                                                name="pageName"
                                                className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                                                required
                                                style={{ color: 'black' }}
                                                onChange={handlePageChange}
                                            >
                                                <option value="" disabled selected>
                                                    Select Page
                                                </option>
                                                {page.map((p) => (
                                                    <option key={p._id} value={p.pageName}>
                                                        {p.pageName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Display the selected pageSlug in an input */}
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedSlug} // Set value from state
                                                    readOnly
                                                    placeholder="Page Slug"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3" name="image" placeholder="Person Image URL" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3" name="personName" placeholder="Person Name" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3" name="personTitle" placeholder="Person Title" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input className="form-control mb-3" name="desc" placeholder="Details" required />
                                            </div>
                                        </div>
                                        <div className="slider-btn text-center">
                                            <button className="btn btn-primary btn-lg" data-delay=".8s">Add Testimonial</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <MarginTop></MarginTop>
            </section>
        </>
    );
};

export default AddTestimonial;
