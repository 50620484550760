// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAARmXMiBQGCMzGBPFV_TXLIi72eXoCdV0",
  authDomain: "seoforsmallbusiness01.firebaseapp.com",
  projectId: "seoforsmallbusiness01",
  storageBucket: "seoforsmallbusiness01.firebasestorage.app",
  messagingSenderId: "716823248181",
  appId: "1:716823248181:web:73d00d0d4597a1cc7ae07c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;