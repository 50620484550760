import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const Pricing = () => {
    const [pricing, setPricing] = useState([]);


    const [page, setPage] = useState([]);
    const [selectedSlug, setSelectedSlug] = useState(""); // State to store pageSlug

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
            .then((res) => res.json())
            .then((info) => setPage(info));
    }, []);

    const handlePageChange = (e) => {
        const selectedPage = page.find((p) => p.pageName === e.target.value);
        setSelectedSlug(selectedPage ? selectedPage.pageSlug : ""); // Store selectedSlug
    };


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/pricings`)
            .then((res) => res.json())
            .then((info) => setPricing(info));
    }, []);


    const handlePricing = (event) => {
        event.preventDefault();
        const pageName = event.target.pageName.value;
        const packageName = event.target.packageName.value;
        const packagePrice = event.target.packagePrice.value;
        const packageButtonText = event.target.packageButtonText.value;
        const packagePointOne = event.target.packagePointOne.value;
        const packagePointTwo = event.target.packagePointTwo.value;
        const packagePointThree = event.target.packagePointThree.value;
        const packagePointFour = event.target.packagePointFour.value;
        const packagePointFive = event.target.packagePointFive.value;
        const packagePointSix = event.target.packagePointSix.value;



        const pricingSection = {
            pageName,
            pageSlug: selectedSlug, // Corrected - adding pageSlug here
            packageName,
            packagePrice,
            packageButtonText,
            packagePointOne,
            packagePointTwo,
            packagePointThree,
            packagePointFour,
            packagePointFive,
            packagePointSix,





        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-pricing`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(pricingSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Pricing is Updated');
            });
    };



    return (
        <>

            <HeaderBottom></HeaderBottom>

            <div>
                <section id="services" class="services-area mt-5" >
                    <div class="container">
                        <div class="row">
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-8 col-md-12 text-center ">
                                <h3 className="mt-5">Service Section Text</h3>
                                <table className="table table-bordered">
                                    <thead className="bg-primary text-white">
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Page Name</th>
                                            <th>Heading About</th>
                                            <th>Edit/Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pricing.map((page, index) => (
                                            <tr key={page._id}>
                                                <td>{index + 1}</td>
                                                <td>{page.pageName}</td>
                                                <td>{page.packageName}</td>
                                                <td>
                                                    <Link to={`/package-edit/${page._id}`} className="btn btn-sm btn-warning">
                                                        <i className="fas fa-edit"></i>  Edit Now
                                                    </Link>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <form class="contact-form " onSubmit={handlePricing}>
                                    <div class="row">

                                        <div className="text-black">
                                            <select
                                                name="pageName"
                                                className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                                                required
                                                style={{ color: 'black' }}
                                                onChange={handlePageChange}
                                            >
                                                <option value="" disabled selected>
                                                    Select Page
                                                </option>
                                                {page.map((p) => (
                                                    <option key={p._id} value={p.pageName}>
                                                        {p.pageName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Display the selected pageSlug in an input */}
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedSlug} // Set value from state
                                                    readOnly
                                                    placeholder="Page Slug"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packageName" placeholder="Package Name" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePrice" placeholder="Package Price" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packageButtonText" placeholder="Button Text" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePointOne" placeholder="Ponint One" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePointTwo" placeholder="Point Two" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePointThree" placeholder="Point Three" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePointFour" placeholder="Point Four" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePointFive" placeholder="Point Five" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="contact-field p-relative c-name mb-20">
                                                <input class="form-control mb-3" name="packagePointSix" placeholder="Point Six" required />
                                            </div>
                                        </div>

                                        <div class="slider-btn">
                                            <button class="btn btn-primary btn-lg" data-delay=".8s"> Add Package</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="demo vh-100">
                <div class="container mt-5 mb-5 ">
                    <div class="row">
                        {/* <h2 className='mb-5'>Edit Your Pricing Plan</h2> */}
                        {/* {
                            pricing.map(pri =>
                                <div class="col-md-4 col-sm-6">
                                    <div class="pricingTable">
                                        <div class="pricingTable-header">

                                            <h3 class="title">{pri.packageName}</h3>

                                        </div>

                                        <div class="price-value">
                                            ${pri.packagePrice}<span></span>
                                        </div>
                                        <div class="pricingTable-signup">
                                            <Link to={`/package-edit/${pri._id}`} className='btn btn-primary btn-lg'>Edit Pricing</Link>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                    </div>
                </div>
            </div>

        </>
    );
};



export default Pricing;