import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateBannerSectionFour = () => {
    const [banner, setBanner] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner-four/${id}`)
            .then((res) => res.json())
            .then((info) => setBanner(info));
    }, [banner]);

    const handleBannerSection = (event) => {
        event.preventDefault();
        const bannerHeadingFour = event.target.bannerHeadingFour.value;
        const bannerDetailsFour = event.target.bannerDetailsFour.value;
        const bannerTextFour = event.target.bannerTextFour.value;
        const imageOneFour = event.target.imageOneFour.value;
        const buttonTextFour = event.target.buttonTextFour.value;
        const buttonURLFour = event.target.buttonURLFour.value;
        const buttonTextTwoFour = event.target.buttonTextTwoFour.value;
        const bannerTextTwoFour = event.target.bannerTextTwoFour.value;

        const bannerSection = {
            bannerHeadingFour,
            bannerDetailsFour,
            bannerTextFour,
            imageOneFour,
            buttonTextFour,
            buttonURLFour,
            buttonTextTwoFour,
           bannerTextTwoFour,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-banner-four/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bannerSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Banner is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <div>
                <section id="services" className="services-area vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h2 className='mb-5 mt-5'>Update Banner</h2>
                                <form className="contact-form" onSubmit={handleBannerSection}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingFour" defaultValue={banner.bannerHeadingFour} placeholder="Banner Heading One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextTwoFour" defaultValue={banner.bannerTextTwoFour} placeholder="Banner Heading Two" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerTextFour" defaultValue={banner.bannerTextFour} placeholder="Banner Heading Three" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerDetailsFour" defaultValue={banner.bannerDetailsFour} placeholder="Banner Details " required />
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="imageOneFour" defaultValue={banner.imageOneFour} placeholder="Image One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextFour" defaultValue={banner.buttonTextFour} placeholder="Button Text" required />
                                            </div>
                                        </div>
                                     
                                        <div className="col-lg-12 col-md-12 ">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextTwoFour" defaultValue={banner.buttonTextTwoFour} placeholder="Reviews Text" required />
                                            </div>
                                        </div>
                                      
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonURLFour" defaultValue={banner.buttonURLFour} placeholder="Button URL" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-center">
                                            <button className="btn btn-primary btn-lg mt-4">Update Banner</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <HeaderBottom />
            </div>
        </>
    );
};

export default UpdateBannerSectionFour;
