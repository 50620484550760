import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TestimonialSection from './../../components/HomePage/TestimonialSection';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditTestimonialtextFive = () => {


    const [testimonialTextFive, settestimonialTextFive] = useState([]);

    const { id } = useParams();


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonialtext-five/${id}`)
            .then((res) => res.json())
            .then((info) => settestimonialTextFive(info));
    }, []);



    const handleTestimonialSection = (event) => {
        event.preventDefault();
        const testimonialTextFive = event.target.testimonialTextFive.value;
        const testimonialHeadingFive = event.target.testimonialHeadingFive.value;
     

        const testimonialSection = {
            testimonialTextFive,
            testimonialHeadingFive,
            
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/testimonialtext-five/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(testimonialSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Testimonial Text Is Updated');
            });
    };








    return (
        <> 
        <HeaderBottom></HeaderBottom>
        <section id="services" class="services-area vh-100">
        <div className='mt-5'>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-12">
                        <form class="contact-form " onSubmit={handleTestimonialSection}>
                        <h3 className='mb-5 text-center'> Update Testimonial Text </h3>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="contact-field p-relative c-name mb-20">
                                        <input  className="form-control mb-3" name="testimonialTextFive" defaultValue={testimonialTextFive.testimonialTextFive} placeholder="Testimonial Text" required />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="contact-field p-relative c-name mb-20">
                                        <input className="form-control mb-3" name="testimonialHeadingFive" defaultValue={testimonialTextFive.testimonialHeadingFive} placeholder="Testimonial Heading" required />
                                    </div>
                                </div>
                                <div class="slider-btn text-center">
                                    <button class="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s"> Update Testimonial Text </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



        </div>
    </section></>
       
    );
};

export default EditTestimonialtextFive;