import React from "react";
import Banner from "../components/Banner";
import AboutSection from "../components/HomePage/AboutSection";
import CounterSection from "../components/CounterSection";
import ServicesSection from "../components/HomePage/ServicesSection";
import PricingSection from "../components/HomePage/PricingSection";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import ServicesFetureSection from "../components/HomePage/ServicesFetureSection";





const Page = () => {


  return (
    <>
        <Banner></Banner>
        <CounterSection></CounterSection>
        <AboutSection></AboutSection>
        <ServicesSection></ServicesSection>
        <ServicesFetureSection></ServicesFetureSection>
        <PricingSection></PricingSection>
       <TestimonialSection></TestimonialSection>
    </>
  );
};

export default Page;
