import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const ServicesFetureSection = () => {


    const [items, setItems] = useState([]);
    const [service, setService] = useState([]);


    const [page, setPage] = useState([]);
    const { pageSlug } = useParams(); // Extract the blog post titleSlug from the URL


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page/${pageSlug}`)
            .then((res) => res.json())
            .then((info) => setPage(info))
            .catch((error) => console.error("Error fetching banners:", error));
    }, []);


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featureitems`)
            .then((res) => res.json())
            .then((info) => setItems(info));
    }, []);


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featurestext`)
            .then((res) => res.json())
            .then((info) => setService(info));
    }, []);


    return (




        // <>
        //     <div
        //         className="features1-section-area sp4 " id="services-sec"
        //         style={{
        //             backgroundImage: "url(assets/images/background/features-bg1.png)",
        //             backgroundRepeat: "no-repeat",
        //             backgroundSize: "cover"
        //         }}
        //     >
        //         <img
        //             src="assets/images/elements/features-elements1.png"
        //             alt=""
        //             className="features-elements1 keyframe4"
        //         />
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-lg-8 m-auto">
        // {
        //     service.map(e => e.pageSlug === '' &&

        //                             <div className="features1-header heading2 text-center">
        //                                 <span data-aos="fade-up" data-aos-duration={800}>
        //                                     {e.serviceHeading}
        //                                 </span>
        //                                 <h2 data-aos="fade-up" data-aos-duration={1000}>
        //                                     {e.servicesubHeading}
        //                                 </h2>
        //                             </div>
        //                         )
        //                     }
        //                     <div className="space60" />
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="col-lg-12">
        //                     <div className="features-box-area">
        //                         <div className="row">

        // {
        //     items.map(b => b.pageSlug === '' &&
        //                                     <div
        //                                         className="col-lg-4 col-md-6"
        //                                         data-aos="fade-up"
        //                                         data-aos-duration={1000}
        //                                     >
        //                                         <div className="features-author-box features2">
        //                                             <a href="features.html">{b.serviceTitle}</a>
        //                                             <p>
        //                                                 {b.serviceDetails}
        //                                             </p>
        //                                             <div className="images">
        //                                                 <img
        //                                                     src={b.serviceIcon}
        //                                                     alt=""
        //                                                 />
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 )
        //                             }
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        // </>

        <><div className="more-features-scetion-area sp4">
            <div className="container">
                <div className="row">
                    {
                        service.map(e => e.pageSlug === page.pageSlug && <div className="col-lg-6 m-auto">
                            <div className="more-features-header text-center heading2">
                                <span data-aos="fade-up" data-aos-duration={800}>{e.FeaturesText}</span>
                                <h2 data-aos="fade-up" data-aos-duration={1000}>{e.FeaturesHeading}</h2>
                            </div>
                        </div>)}


                </div>
                <div className="space60" />
                <div className="row">

                    {
                        items.map(b => b.pageSlug === page.pageSlug &&

                            <div className="col-lg-4 col-md-6" data-aos="fade-right" data-aos-duration={1000}>

                                <div className="more-features-box">
                                    <img src="assets/images/elements/more-elements.png" alt className="more-elements keyframe4" />
                                    <div className="features-icon">
                                        <img src={b.FeaturesIcon} alt />
                                    </div>
                                    <div className="features-content">
                                        <a href="/">{b.FeaturesText}</a>
                                        <p>{b.FeaturesDesc}</p>

                                    </div>
                                </div>

                            </div>
                        )}
                </div>
            </div>
        </div>
        </>

    );
};

export default ServicesFetureSection;
