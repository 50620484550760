import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);
  const [footerSocial, setFooterSocial] = useState([]);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;

    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Message is sent');
        navigate('/thanks');  // Navigate to /thanks route
      })
      .catch((error) => {
        console.error('Error:', error);

      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);

  return (



    <>
      {/*=====Contact STARTS =======*/}
      <div className="about-welcome-section-area about2" style={{ backgroundImage: 'url(assets/images/background/header2-bg.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} id='contact-sec'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              {
                footerAddress.map(d => <div className="contact-inner-header heading1">
                  <h1 className="text-start">Get In Touch</h1>
                  <div className="spasce16" />
                  <p></p>
                  <div className="space32" />
                  <h3>Contact Information:</h3>
                  <div className="space32" />
                  <div className="contact-box-area">
                    <div className="contact-boxs">
                      <div className="img1">
                        <img src="assets/images/icons/call-img1.svg" alt />
                      </div>
                      <div className="content">
                        <p>Contact Us</p>
                        <div className="space12" />
                        <a href="/">{d.phoneNumber}</a>
                      </div>
                    </div>
                    <div className="space24" />
                    <div className="contact-boxs">
                      <div className="img1">
                        <img src="assets/images/icons/email-img1.svg" alt />
                      </div>
                      <div className="content">
                        <p>Send Us a Mail</p>
                        <div className="space12" />
                        <a href="/">{d.emailAddress}</a>
                      </div>
                    </div>
                  </div>
                </div>)
              }

            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6">
              <div className="conatact-header-boxarea heading6">
                <h2>Contact Us</h2>
                <form onSubmit={handleMessage} >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="space24" />
                      <div className="input-area">
                        <input type="text" name="name" required placeholder="Your Name" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="space24" />
                      <div className="input-area">
                        <input type="number" name="number" placeholder="Your Phone" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="space24" />
                      <div className="input-area">
                        <input type="email" name="email" required placeholder="Your Email" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="space24" />
                      <div className="input-area">
                        <input type="text" name="subject" placeholder="Your Subject" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="" />
                      <div className="">
                        <input
                          name="messageStatus"
                          value="UnRead"
                          hidden
                          placeholder="Message Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="space24" />
                      <div className="input-area">
                        <textarea name="message" placeholder="Additional Details" cols={30} rows={10} defaultValue={""} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="space24" />
                      <div className="div">
                        <button type="submit" className="header-btn2">Send Now</button>
                      </div>
                    </div>
                  </div>

                </form>



              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=====Contact ENDS =======*/}</>
  );
};

export default ContactUs;
