// src/components/ErrorPage.js

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HeaderBottom from '../HomePage/HeaderBottom';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>

<>
  {/*===== WELCOME STARTS =======*/}
  <div
    className="about-welcome-section-area about2"
    style={{
      backgroundImage: "url(assets/images/background/header2-bg.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-4 m-auto">
          <div className="about-welcome-header text-center heading3">
            <h1>404 Error</h1>
            <div className="space16" />
            <a href="/">
              Home <i className="fa-solid fa-angle-right" /> <span>404</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*===== WELCOME ENDS =======*/}
  {/*===== 404 STARTS =======*/}
  <div className="error-section-area sp3">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 m-auto">
          <div className="error-content text-center">
            <img src="https://i.ibb.co.com/LXQM7M63/404-img.png" alt="" />
            <div className="space60" />
            <div className="content-area">
              <h3>Page Not Found!</h3>
              <p>
                Sorry, the page you are looking for doesn’t exist or has{" "}
                <br className="d-lg-block d-none" /> been moved. Here are some
                helpful links.
              </p>
              <a href="/" className="header-btn2">
                Take Me Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*===== 404 ENDS =======*/}
</>



    </>
  );
};

export default ErrorPage;
