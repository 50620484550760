import React from "react";
import Banner from "../components/Banner";
import ServicesSection from "../components/HomePage/ServicesSection";
import AboutSection from "../components/HomePage/AboutSection";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import PricingSection from "../components/HomePage/PricingSection";
import ContactUs from "./ContactUs";
import WorkSection from "../components/HomePage/WorkSection";
import FAQSection from "../components/HomePage/FAQSection";
import CallToAction from "../components/HomePage/CallToAction";
import BannerFour from "../components/BannerFour";
import ServicesSectionFour from "../components/HomePage/ServicesSectionFour";
import AboutSectionFour from "../components/HomePage/AboutSectionFour";
import FAQSectionFour from "../components/HomePage/FAQSectionFour";
import TestimonialSectioFour from "../components/HomePage/TestimonialSectioFour";





const HomeFour = () => {

  return (
    <div className="boxed_wrapper ltr">

      <BannerFour></BannerFour>
      <ServicesSectionFour></ServicesSectionFour>
      <AboutSectionFour></AboutSectionFour>
      <FAQSectionFour></FAQSectionFour>
      <TestimonialSectioFour></TestimonialSectioFour>
      <CallToAction></CallToAction>

    </div>
  );
};

export default HomeFour;
