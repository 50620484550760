import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const BannerSection = () => {
  const [banner, setBanner] = useState([]);
  const [page, setPage] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState("");  // State to store pageSlug

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanner(info));
  }, []);

  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
      .then((res) => res.json())
      .then((info) => setPage(info));
  }, []);

  const handlePageChange = (e) => {
    const selectedPage = page.find((p) => p.pageName === e.target.value);
    setSelectedSlug(selectedPage ? selectedPage.pageSlug : "");
  };

  const handleBannerSection = (event) => {
    event.preventDefault();

    const pageName = event.target.pageName.value;
    const bannerHeading = event.target.bannerHeading.value;
    const bannerDetails = event.target.bannerDetails.value;
    const bannerText = event.target.bannerText.value;
    const imageOne = event.target.imageOne.value;
    const buttonText = event.target.buttonText.value;
    const buttonURL = event.target.buttonURL.value;
    const buttonTextTwo = event.target.buttonTextTwo.value;
    const buttonURLTwo = event.target.buttonURLTwo.value;
    const bannerTextTwo = event.target.bannerTextTwo.value;

    const bannerSection = {
      pageName,
      pageSlug: selectedSlug,  // Add selectedSlug here as pageSlug
      bannerHeading,
      bannerDetails,
      bannerText,
      imageOne,
      buttonText,
      buttonURL,
      buttonTextTwo,
      buttonURLTwo,
      bannerTextTwo,
    };

    const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-banner`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bannerSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Banner is Updated');
      });
  };

  return (
    <>
      <HeaderBottom />

      <div>
        <section id="services" className="services-area d-flex align-items-center">
          <div className="container">

            {/* Display Pages in a Table */}
            <h3 className="mt-5">Page List</h3>
            <table className="table table-bordered">
              <thead className="bg-primary text-white">
                <tr>
                  <th>Sl No</th>
                  <th>Page Name</th>
                  <th>Heading</th>
                  <th>Edit/Action</th>
                </tr>
              </thead>
              <tbody>
                {banner.map((page, index) => (
                  <tr key={page._id}>
                    <td>{index + 1}</td>
                    <td>{page.pageName}</td>
                    <td>{page.bannerHeading}</td>
                    <td><Link to={`/banner/${page._id}`} className="btn btn-sm btn-warning">
                      <i className="fas fa-edit"></i>  Edit Now
                    </Link></td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 text-center">
                <h2 className="mb-5 mt-5">Add Banner</h2>

                <form className="contact-form text-center" onSubmit={handleBannerSection}>
                  <div className="row">
                    <div className="text-black">
                      <select
                        name="pageName"
                        className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                        required
                        style={{ color: 'black' }}
                        onChange={handlePageChange}  // Handle change
                      >
                        <option value="" disabled selected>
                          Select Page
                        </option>
                        {page.map((p) => (
                          <option key={p._id} value={p.pageName}>
                            {p.pageName}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Display the selected pageSlug in an input */}
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={selectedSlug}  // Set value from state
                          readOnly
                          placeholder="Page Slug"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control mb-3"
                          name="bannerHeading"
                          placeholder="Banner Heading One"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="bannerTextTwo"
                          placeholder="Banner Heading Two"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="bannerText"
                          placeholder="Banner Heading Three"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="bannerDetails"
                          placeholder="Banner Details"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="imageOne"
                          placeholder="Banner Image"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="buttonText"
                          placeholder="Button Text"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="buttonURL"
                          placeholder="Button URL"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="buttonTextTwo"
                          placeholder="Second Button Text"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="buttonURLTwo"
                          placeholder="Second Button URL"
                          required
                        />
                      </div>
                    </div>

                    <div className="slider-btn text-center">
                      <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">
                        Add Banner
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </section>
        <MarginTop></MarginTop>
      </div>
    </>
  );
};

export default BannerSection;
