import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);
  const [logo, setLogo] = useState([]);
  const [footerAddress, setFooterAddress] = useState([]);
  const [isScrollVisible, setIsScrollVisible] = useState(false); // State for "Scroll to Top" button visibility

  // Fetch logo data
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);

  // Fetch footer address
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, []);

  // Fetch social links
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  // Fetch copyright
  useEffect(() => {
    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, []);

  // Handle form submission for subscribers
  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;

    const addItem = { subemail };

    fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-subscriber`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then(() => {
        alert("Subscription request sent successfully!");
      });
  };

  // Show "Scroll to Top" button when scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>

      <div className="footer-section-area" style={{ backgroundImage: 'url(assets/images/background/footer-bg1.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <img src="assets/images/elements/footer-elements.png" alt className="footer-elements keyframe4" />
        <div className="container">
          <div className="row pt-5">

            <div className="col-lg-12">
              <div className="footer-last-section">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    {
                      footerSocial.map(s => <div className="footer-auhtor-area">
                        {
                          logo.map(l => <img src={l.logo} alt />)
                        }

                        <p>{s.aboutText}</p>
                        <ul className="social-links">
                          <li><a href={s.fblink}><i className="fa-brands fa-facebook" /></a></li>
                          <li><a href={s.youlink}><i className="fa-brands fa-youtube" /></a></li>
                          <li><a href={s.inslink}><i className="fa-brands fa-instagram" /></a></li>
                        </ul>
                      </div>
                      )
                    }


                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <h3>Quick Links</h3>
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="#about-sec">About</a></li>
                        <li><a href="#services-sec">Services</a></li>
                        <li><a href="#pricing-sec">Pricing</a></li>
                        <li><a href="/blog">Blog</a></li>
                        <li><a href="#contact-sec">Contact Us</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <h3>Help & Supports</h3>
                      {
                        footerAddress.map(a => <ul class="list-unstyled text-white">
                          <li class="mb-2">
                            <i class="bi bi-geo-alt me-2"></i>
                            <a href="/" class="text-white text-decoration-none">Email: {a.emailAddress}</a>
                          </li>
                          <li class="mb-2">
                            <i class="bi bi-telephone-fill me-2"></i>
                            <a href="/" class="text-white text-decoration-none">Phone: {a.phoneNumber}</a>
                          </li>
                          <li>
                            <i class="fa-brands fa-geo-alt-fill me-2"></i>
                            <a href="/" class="text-white text-decoration-none">Location: {a.location}</a>
                          </li>
                        </ul>)
                      }


                    </div>
                  </div>



                  <div className="col-lg-">
                    <div className="copyright-social-area">
                      {
                        footerCopyright.map(c => <ul>
                          <li className="copy"><p>{c.copyrightText}</p></li>
                        </ul>)
                      }
                      <ul>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section Container */}
            {/* Background Gradient */}
            <div className="absolute left-1/2 top-[80%] -z-[1] h-[1280px] w-[1280px] -translate-x-1/2 rounded-full bg-gradient-to-t from-[#5636C7] to-[#5028DD] blur-[250px]">
            </div>

            {/* Scroll to Top Button */}
            {isScrollVisible && (
              <button
                className="btn position-fixed bottom-0 end-0 m-3"
                onClick={scrollToTop}
                style={{
                  zIndex: 1000,
                  backgroundColor: '#ff0000',  // Red color for the button
                  borderRadius: '50%',         // Make the button fully round
                  padding: '20px',              // Adjust padding for appropriate size
                  color: 'white',              // White text color for contrast
                  border: 'none',              // Remove border
                  fontSize: '15px',            // Make text size appropriate and readable
                  fontWeight: 'bold',          // Bold text for better visibility
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',  // Add subtle shadow for modern look
                  cursor: 'pointer',          // Pointer cursor to indicate it's clickable
                  transition: 'all 0.3s ease', // Smooth transition on hover
                  width: '60px',              // Ensure the button has a fixed width
                  height: '60px',             // Ensure the button has a fixed height (making it a perfect circle)
                }}
                onMouseOver={(e) => {
                  e.target.style.transform = 'scale(1.1)'; // Button scale on hover
                }}
                onMouseOut={(e) => {
                  e.target.style.transform = 'scale(1)';   // Revert button scale when hover ends
                }}
              >
                ↑
              </button>
            )}


          </div>

        </div>

      </div>



    </>
  );
};

export default Footer;
