import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const FAQtextEditOne = () => {

    const [faqTextOne, setService] = useState([]);
    const { id } = useParams();


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/faq-one/${id}`)
            .then((res) => res.json())
            .then((info) => setService(info));
    }, []);




    const handlefaqTextOne = (event) => {
        event.preventDefault();
        const faqTextOne = event.target.faqTextOne.value;
        const faqHeadingOne = event.target.faqHeadingOne.value;
        const faqImageOne = event.target.faqImageOne.value;



        const faqSection = {
            faqTextOne,
            faqHeadingOne,
            faqImageOne,


        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/faq-one/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(faqSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' FAQ Text is Updated');
            });
    };




    return (
        <>
            <HeaderBottom></HeaderBottom>
            {
                <section id="services" className="services-area  vh-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h3>Update FAQ Text</h3>

                                <form className="contact-form" onSubmit={handlefaqTextOne}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name ">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="faqTextOne"
                                                    defaultValue={faqTextOne.faqTextOne}
                                                    placeholder="FAQ Text"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name ">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="faqHeadingOne"
                                                    defaultValue={faqTextOne.faqHeadingOne}
                                                    placeholder="FAQ Heading"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name ">
                                                <input
                                                    className="form-control form-control-lg shadow-lg p-3 mb-3 bg-white rounded"
                                                    name="faqImageOne"
                                                    defaultValue={faqTextOne.faqImageOne}
                                                    placeholder="FAQ Image"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="slider-btn text-center">
                                            <button
                                                className="btn btn-primary btn-lg"
                                                data-animation="fadeInRight"
                                                data-delay=".8s"
                                            >
                                                Update FAQ Text
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default FAQtextEditOne;