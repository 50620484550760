import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import MarginTop from '../../MarginTop';

const Counter = () => {
    const [countersec, setCounter] = useState([]);


    const [page, setPage] = useState([]);
    const [selectedSlug, setSelectedSlug] = useState(""); // State to store pageSlug


    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/page`)
            .then((res) => res.json())
            .then((info) => setPage(info));
    }, []);

    const handlePageChange = (e) => {
        const selectedPage = page.find((p) => p.pageName === e.target.value);
        setSelectedSlug(selectedPage ? selectedPage.pageSlug : ""); // Store selectedSlug
    };

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/counters`)
            .then((res) => res.json())
            .then((info) => setCounter(info));
    }, []);

    const handleCounterSection = (event) => {
        event.preventDefault();

        const pageName = event.target.pageName.value;
        const happyClientsNumber = event.target.happyClientsNumber.value;
        const happyClientsText = event.target.happyClientsText.value;
        const projectCompleteNumber = event.target.projectCompleteNumber.value;
        const projectCompleteText = event.target.projectCompleteText.value;
        const yearofExperienceNumber = event.target.yearofExperienceNumber.value;
        const yearofExperienceText = event.target.yearofExperienceText.value;
        const teamNumber = event.target.teamNumber.value;
        const teamText = event.target.teamText.value;

        const counterSection = {

            pageName,
            pageSlug: selectedSlug, // Corrected - adding pageSlug here
            happyClientsNumber,
            happyClientsText,
            projectCompleteNumber,
            projectCompleteText,
            yearofExperienceNumber,
            yearofExperienceText,
            teamNumber,
            teamText,
        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/add-counter`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(counterSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Counter is Updated');
            });
    };

    return (
        <>
            <HeaderBottom></HeaderBottom>
            <div>
                <section id="services" className="services-area  d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-8 col-md-12">

                                {/* Display Pages in a Table */}
                                <h3 className="mt-5">Page List</h3>
                                <table className="table table-bordered">
                                    <thead className="bg-primary text-white">
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Page Name</th>
                                            <th>Heading Counter</th>
                                            <th>Edit/Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {countersec.map((page, index) => (
                                            <tr key={page._id}>
                                                <td>{index + 1}</td>
                                                <td>{page.pageName}</td>
                                                <td>{page.happyClientsText}</td>
                                                <td><td><Link to={`/counters/${page._id}`} className="btn btn-sm btn-warning">
                                                    <i className="fas fa-edit"></i>  Edit Now
                                                </Link></td></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <form className="contact-form" onSubmit={handleCounterSection}>

                                    <div className="text-black">
                                        <select
                                            name="pageName"
                                            className="form-select w-100 px-3 py-2 mb-3 border border-gray-300 rounded-lg"
                                            required
                                            style={{ color: 'black' }}
                                            onChange={handlePageChange}
                                        >
                                            <option value="" disabled selected>
                                                Select Page
                                            </option>
                                            {page.map((p) => (
                                                <option key={p._id} value={p.pageName}>
                                                    {p.pageName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Display the selected pageSlug in an input */}
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={selectedSlug} // Set value from state
                                                readOnly
                                                placeholder="Page Slug"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="happyClientsNumber"
                                                placeholder="Happy Clients Number"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="happyClientsText"
                                                placeholder="Happy Clients Text"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="projectCompleteNumber"
                                                placeholder="Project Complete Number"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="projectCompleteText"
                                                placeholder="Project Complete Text"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="yearofExperienceNumber"
                                                placeholder="Year of Experience Number"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="yearofExperienceText"
                                                placeholder="Year of Experience Text"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="teamNumber"
                                                placeholder="Team Member Number"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                name="teamText"
                                                placeholder="Team Member Text"
                                                required
                                            />
                                        </div>
                                        <div className="slider-btn text-center">
                                            <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">
                                                Update Counter
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <MarginTop></MarginTop>
        </>
    );
};

export default Counter;
