import React from "react";
import Banner from "../components/Banner";
import AboutSection from "../components/HomePage/AboutSection";
import PricingSection from "../components/HomePage/PricingSection";
import BannerForHome from "../components/BannerForHome";
import ServicesSection from "../components/HomePage/ServicesSection";
import CounterSection from "../components/CounterSection";
import AboutSectionForHome from "../components/HomePage/AboutSectionForHome";
import CounterSectionForHome from "../components/CounterSectionForHome";
import ServicesSectionForHome from "../components/HomePage/ServicesSectionForHome";
import PricingSectionForHome from "../components/HomePage/PricingSectionForHome";
import TestimonialSection from "../components/HomePage/TestimonialSection";
import TestimonialSectionForHome from "../components/HomePage/TestimonialSectionForHome";
import ContactUs from "./ContactUs";
import ServicesFetureSectionForHome from "../components/HomePage/ServicesFetureSectionForHome";





const Home = () => {


  return (
    <>

      <BannerForHome></BannerForHome>
      <CounterSectionForHome></CounterSectionForHome>
      <AboutSectionForHome></AboutSectionForHome>
      <ServicesSectionForHome></ServicesSectionForHome>
      <ServicesFetureSectionForHome></ServicesFetureSectionForHome>
      <PricingSectionForHome></PricingSectionForHome>
      <TestimonialSectionForHome></TestimonialSectionForHome>
      <ContactUs></ContactUs>


    </>
  );
};

export default Home;
