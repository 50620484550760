import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import HeaderBottom from '../../components/HomePage/HeaderBottom';

const EditFeaturesServicesItem = () => {


    const [featureitems, setFeatureItems] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/featureitems/${id}`)
            .then((res) => res.json())
            .then((info) => setFeatureItems(info));
        console.log(featureitems)
    }, []);


    const handleItemSection = (event) => {
        event.preventDefault();
        const FeaturesIcon = event.target.FeaturesIcon.value;
        const FeaturesText = event.target.FeaturesText.value;
        const FeaturesDesc = event.target.FeaturesDesc.value;



        const featuresSection = {

            FeaturesIcon,
            FeaturesText,
            FeaturesDesc,

        };

        const url = `https://seoforsmallbusiness01-5899b60ec80a.herokuapp.com/update-featureitems/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(featuresSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert('Feature Items Section is Updated');
            });
    };



    return (
        <>
            <HeaderBottom></HeaderBottom>

            <div className="container vh-100 d-flex flex-column align-items-center justify-content-center text-center">
                <section id="services" className="services-area pt-120 pb-90 fix w-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="mb-4">Update Feature</h3>
                                <form className="contact-form w-100" onSubmit={handleItemSection}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-3">
                                                <input className="form-control" name="FeaturesIcon" defaultValue={featureitems.FeaturesIcon} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-3">
                                                <input className="form-control " name="FeaturesText" defaultValue={featureitems.FeaturesText} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-3">
                                                <input className="form-control " name="FeaturesDesc" defaultValue={featureitems.FeaturesDesc} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <button className="btn btn-primary btn-lg" data-animation="fadeInRight" data-delay=".8s">
                                                Update Feature
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
};

export default EditFeaturesServicesItem;